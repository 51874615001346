import Page from '@admin/components/page'
import Overview from './overview'
import React from 'react'

const mapPropsToPage = (props, context, resources, page) => ({
  title: resources.demo.contact.display_name,
  manager: {
    path: `/admin/truevail/admin/demos/${resources.demo.id}`,
    items: [
      {
        label: 't(Overview)',
        panel: <Overview demo={ resources.demo } />
      }
    ]
  }
})

const mapResourcesToPage = (props, context) => ({
  demo: `/api/admin/truevail/admin/demos/${props.params.id}`
})

export default Page(mapResourcesToPage, mapPropsToPage)
