import DeliverabilityRate from '@admin/tokens/deliverability_rate'
import Panel from '@admin/components/panel'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Sent)',
  collection: {
    endpoint: '/api/admin/truevail/admin/processings',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Segment)', key: 'segment.text', sort: 'segment', primary: true },
      { label: 't(Sent)', key: 'sent_count', collapsing: true, align: 'right' },
      { label: 't(Open)', key: 'open_rate', collapsing: true, align: 'right', format: (domain) => (
        <DeliverabilityRate value={ domain.open_rate } />
      ) },
      { label: 't(Click)', key: 'click_rate', collapsing: true, align: 'right', format: (domain) => (
        <DeliverabilityRate value={ domain.click_rate } />
      ) },
      { label: 't(Bounce)', key: 'bounce_rate', collapsing: true, align: 'right', format: (domain) => (
        <DeliverabilityRate value={ domain.bounce_rate } highlight={ domain.bounce_rate >= 0.05 } />
      ) },
      { label: 't(Soft Bounce)', key: 'soft_bounce_rate', collapsing: true, align: 'right', format: (domain) => (
        <DeliverabilityRate value={ domain.soft_bounce_rate } highlight={ domain.soft_bounce_rate >= 0.05 } />
      ) },
      { label: 't(Hard Bounce)', key: 'hard_bounce_rate', collapsing: true, align: 'right', format: (domain) => (
        <DeliverabilityRate value={ domain.hard_bounce_rate } highlight={ domain.hard_bounce_rate >= 0.05 } />
      ) },
      { label: 't(Complain)', key: 'complain_rate', collapsing: true, align: 'right', format: (domain) => (
        <DeliverabilityRate value={ domain.complain_rate } highlight={ domain.complain_rate >= 0.001 } />
      ) },
      { label: 't(Unsubscribe)', key: 'unsubscribe_rate', collapsing: true, align: 'right', format: (domain) => (
        <DeliverabilityRate value={ domain.unsubscribe_rate } />
      ) }
    ],
    defaultQuery: { 
      ...props.params.domain_id ? { 
        domain_id: props.params.domain_id
      } : {},
      interval: props.params.interval === 'day' ? 'hour' : 'minute',
      group_by: 'segment',
      value: props.params.value
    },
    defaultSort: { key: 'segment', order: 'asc' },
    empty: {
      icon: 'envelope',
      title: 't(No Emails)',
      text: 't(There are no sent emails)'
    },
    entity: 'segments',
    onClick: (record) => {
      const parts = ['/admin/truevail/admin/sent']
      if(props.params.domain_id) parts.push(`domain_segments/${record.domain_segment.id}`)
      else parts.push(`segments/${record.segment.id}`)
      if(props.params.interval === 'day') parts.push(`day/${props.params.value}`)
      if(props.params.interval === 'hour') parts.push(`hour/${props.params.value}`)
      context.router.push(parts.join('/'))
    }  
  }
})

export default Panel(null, mapPropsToPanel)
