import ContactToken from '@apps/crm/admin/tokens/contact'
import Page from '@admin/components/page'
import React from 'react'

const mapPropsToPage = (props, context) => ({
  title: 't(Subscriptions)',
  access: { rights: { $allOf: ['dashboards:access_app','subscriptions:view_services'] } },
  collection: {
    endpoint: '/api/admin/dashboards/subscriptions/cancellations',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Contact)', key: 'subscription.contact.display_name', sort: 'last_name', primary: true, format: ({ subscription }) => {
        return <ContactToken { ...subscription.contact } />
      } },
      { label: 't(Organization)', key: 'subscription.position.organization', sort: 'organization' },
      { label: 't(Plan)', key: 'plan.title', sort: 'plan' },
      { label: 't(Type)', key: 'type' },
      { label: 't(Canceled)', key: 'subscription.canceled_on', sort: 'canceled_on', format: 'date' }
    ],
    defaultSort: { key: 'created_at', order: 'desc' },
    empty: {
      icon: 'check',
      title: 't(No Cancellations)',
      text: 't(There were no cancellations during this time period)'
    },
    entity: 'email',
    onClick: (record) => context.router.push(`/subscriptions/services/${record.subscription.service.id}/subscriptions/${record.subscription.id}`)
  }
})

export default Page(null, mapPropsToPage)
