import CheckboxGroup from '@admin/components/form/checkboxgroup'
import InputTokens from '@admin/components/input_tokens'
import DropDown from '@admin/components/form/dropdown'
import { toOxford } from '@core/utils/oxford'
import Button from '@admin/components/button'
import Registrations from './registrations'
import Subscriptions from './subscriptions'
import Participants from './participants'
import Responses from './responses'
import PropTypes from 'prop-types'
import { toFilter } from './utils'
import Contacts from './contacts'
import pluralize from 'pluralize'
import Truevail from './truevail'
import Filter from './filter'
import Picker from './picker'
import Orders from './orders'
import Deals from './deals'
import React from 'react'
import List from './list'

class ToField extends React.PureComponent {

  static contextTypes = {
    admin: PropTypes.object,
    form: PropTypes.object,
    network: PropTypes.object
  }

  static propTypes = {
    channel: PropTypes.string,
    defaultValue: PropTypes.object,
    event: PropTypes.object,
    form: PropTypes.object,
    pipeline: PropTypes.object,
    program_id: PropTypes.number,
    purpose: PropTypes.string,
    required: PropTypes.bool,
    service: PropTypes.object,
    store: PropTypes.object,
    strategies: PropTypes.array,
    value: PropTypes.object,
    webinar: PropTypes.object,
    onChange: PropTypes.func,
    onReady: PropTypes.func,
    onValid: PropTypes.func
  }

  state = {
    dont_send_config: null,
    dont_send_recipients: 0,
    dont_send_strategy: null,
    engagement: ['engaged','disengaged','inactive'],
    send_config: null,
    send_recipients: 0,
    send_strategy: null,
    total_recipients: 0
  }

  _handleEnagement = this._handleEnagement.bind(this)
  _handleValidate = this._handleValidate.bind(this)

  render() {
    const { dont_send_config, dont_send_strategy, send_config, send_strategy, total_recipients } = this.state
    const { channel, purpose } = this.props
    return (
      <div className="tofield">
        <div className="tofield-body">
          <div className="tofield-input">
            <div className="maha-form-field-label">
              <label>Send To:</label>
            </div>
            <div className="tofield-field">
              <DropDown { ...this._getStrategy('send') } />
              { send_strategy &&
                <>
                  { channel === 'email' && purpose === 'marketing' &&
                    <>
                      <div className="maha-form-field-label">
                        <label>With Engagement Status:</label>
                      </div>
                      <CheckboxGroup { ...this._getEngagement() } />
                    </>
                  }
                  <div className="maha-input" onClick={ this._handlePicker.bind(this, 'send') }>
                    <div className="maha-input-field">
                      { send_config ?
                        <InputTokens { ...this._getSendTokens() } /> :
                        <div className="maha-input-placeholder">
                          { this._getPlaceholder('send') }
                        </div>
                      }
                    </div>
                    { send_config &&
                      <Button { ...this._getClear('send') } />
                    }
                  </div>
                </>
              }
            </div>
          </div>
          <div className="tofield-input">
            <div className="maha-form-field-label">
              <label>Dont Send To:</label>
            </div>
            <div className="tofield-field">
              <DropDown { ...this._getStrategy('dont_send') } />
              { dont_send_strategy &&
                <div className="maha-input" onClick={ this._handlePicker.bind(this, 'dont_send') }>
                  <div className="maha-input-field">
                    { dont_send_config ?
                      <InputTokens { ...this._getDontSendTokens() } /> :
                      <div className="maha-input-placeholder">
                        { this._getPlaceholder('dont_send') }
                      </div>
                    }
                  </div>
                  { dont_send_config &&
                    <Button { ...this._getClear('dont_send') } />
                  }
                </div>
              }
            </div>
          </div>
        </div>
        <div className="tofield-footer">
          This campaign will be sent to a total of <strong>{ pluralize('recipient', total_recipients, true) }</strong>
        </div>
      </div>
    )
  }

  componentDidMount() {
    const defaultValue = this._getDefaultValue()
    if(defaultValue) this._handleSet(defaultValue)
    this.props.onReady(this._handleValidate)
  }

  componentDidUpdate(prevProps, prevState) {
    const { dont_send_strategy, dont_send_config, send_strategy, send_config, engagement } = this.state
    if(!_.isEqual(send_config, prevState.send_config)) {
      this._handleChange()
      this._handleFetch()
    }
    if(!_.isEqual(dont_send_config, prevState.dont_send_config)) {
      this._handleChange()
      this._handleFetch()
    }
    if(!_.isEqual(engagement, prevState.engagement)) {
      this._handleChange()
      this._handleFetch()
    }
    if(send_strategy !== prevState.send_strategy) {
      this._handleChange()
      this._handleFetch()
    }
    if(dont_send_strategy !== prevState.dont_send_strategy) {
      this._handleChange()
      this._handleFetch()
    }
  }

  _getChannel() {
    const { channel } = this.props
    if(channel === 'email') return ['email address','emails']
    if(channel === 'sms') return ['phone number','sms messages']
    if(channel === 'voice') return ['phone number','calls']
    if(channel === 'mail') return ['mailing address','mailings']
    if(channel === 'all') return ['contact','enrollments']
  }

  _getClear(key) {
    return {
      icon: 'times',
      className: 'maha-input-action',
      handler: this._handleClear.bind(this, key)
    }
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : null
  }

  _getDontSendTokens() {
    const { dont_send_recipients, send_recipients } = this.state
    return {
      tokens: [`${ dont_send_recipients } of the ${ pluralize('recipient', send_recipients, true) }`]
    }
  }

  _getEngagement() {
    return {
      options: [
        { value: 'engaged', text: 't(Engaged - Contact opened or clicked on one of your last 4 emails)' },
        { value: 'disengaged', text: 't(Disengaged - Contact opened or clicked on one of your last 5 - 8 emails)' },
        { value: 'inactive', text: 't(Inactive - Contact has not opened or clicked on any of your last 8 emails)' }
      ],
      defaultValue: ['engaged','disengaged'],
      onChange: this._handleEnagement
    }
  }

  _getInstructions() {
    const { engagement } = this.state
    const { purpose } = this.props
    const channel = this._getChannel()
    if(purpose === 'marketing') {
      return (
        <>
          Marketing { channel[1] } can only be sent to contacts who have given their
          explicit consent. You will only see contacts who <strong>match your 
          criteria</strong> and <strong>have given consent to receive { channel[1] } from this program</strong> { 
            channel === 'email' ? `and <strong>have an enagement status of ${this._getOxford(engagement)}</strong>` : '' 
          }
        </>
      )
    }
    if(purpose === 'transactional') {
      return (
        <>
          Transactional {channel[1]} will be sent to the first {channel[0]} of each
          contact that matches your criteria. You will only see contacts who <strong>have an active { channel[0] }</strong>
        </>
      )
    }
  }

  _getOxford(list) {
    const items = list.map(item => `'${item}'`)
    return toOxford(items, 'or')
  }

  _getPicker(key) {
    const { channel, event, form, pipeline, program_id, purpose, service, store, webinar } = this.props
    const config = this.state[`${key}_config`]
    return {
      channel,
      defaultValue: config,
      endpoint: '/api/admin/crm/recipients',
      event,
      form,
      instructions: this._getInstructions(),
      pipeline,
      program_id,
      purpose,
      service,
      store,
      webinar,
      onDone: this._handleUpdate.bind(this, key)
    }
  }

  _getPlaceholder(key) {
    const strategy = this.state[`${key}_strategy`]
    if(strategy === 'contacts') return 'Choose contacts'
    if(strategy === 'criteria') return 'Choose custom criteria'
    if(strategy === 'deals') return 'Choose deal criteria'
    if(strategy === 'registrations') return 'Choose registration criteria'
    if(strategy === 'filter') return 'Choose saved filter'
    if(strategy === 'responses') return 'Choose response criteria'
    if(strategy === 'list') return 'Choose list'
    if(strategy === 'orders') return 'Choose order criteria'
    if(strategy === 'participants') return 'Choose participant criteria'
    if(strategy === 'subscriptions') return 'Choose subscription criteria'
    if(strategy === 'truevail') return 'Choose criteria'
  }

  _getQuery() {
    const { dont_send_config, dont_send_strategy, engagement, send_config, send_strategy } = this.state
    const { channel, event, form, pipeline, program_id, purpose, service, store, webinar } = this.props
    return {
      program_id,
      purpose,
      channel,
      engagement,
      send_strategy,
      send_config: {
        ...event ? { event_id: event.id } : {},
        ...form ? { form_id: form.id } : {},
        ...pipeline ? { pipeline_id: pipeline.id } : {},
        ...service ? { service_id: service.id } : {},
        ...store ? { store_id: store.id } : {},
        ...webinar ? { webinar_id: webinar.id } : {},
        ..._.omit(send_config, 'criteria'),
        ...send_config.criteria ? {
          filter: toFilter(send_config.criteria)
        } : {}
      },
      ...dont_send_config ? {
        dont_send_strategy,
        dont_send_config: {
          ...event ? { event_id: event.id } : {},
          ...form ? { form_id: form.id } : {},
          ...pipeline ? { pipeline_id: pipeline.id } : {},
          ...service ? { service_id: service.id } : {},
          ...store ? { store_id: store.id } : {},
          ...webinar ? { webinar_id: webinar.id } : {},
          ..._.omit(dont_send_config, 'criteria'),
          ...dont_send_config.criteria ? {
            filter: toFilter(dont_send_config.criteria)
          } : {}
        }
      } : {}
    }
  }

  _getSendTokens() {
    const { send_recipients } = this.state
    return {
      tokens: [pluralize('recipient', send_recipients, true)]
    }
  }

  _getStrategy(key) {
    const strategies = this._getStrategies(key)
    return {
      deselectable: false,
      options: strategies.map(({ strategy, text }) => ({
        value: strategy,
        text
      })),
      placeholder: 't(Choose Strategy)',
      defaultValue: this.state[`${key}_strategy`],
      onChange: this._handleStrategy.bind(this, key)
    }
  }

  _getStrategies(key) {
    const { event, form, pipeline, strategies, service, store, webinar } = this.props
    const { team } = this.context.admin
    return [
      ...key === 'dont_send' ? [
        { strategy: 'none', text: 't(Dont apply filter)' }
      ] : [],
      ...strategies || [],
      ...pipeline ? [
        { strategy: 'deals', text: 't(Contacts that have a deal in this pipeline)', component: Deals, placeholder: 't(Choose Deals)' }
      ] : event ? [
        { strategy: 'registrations', text: 't(Contacts that registered for this event)', component: Registrations, placeholder: 't(Choose Registrants)' }
      ] : form ? [
        { strategy: 'responses', text: 't(Contacts that responded to this form)', component: Responses, placeholder: 't(Choose Respondants)' }
      ] : store ? [
        { strategy: 'orders', text: 't(Contacts that ordered items from this store)', component: Orders, placeholder: 't(Choose Customers)' }
      ] : service ? [
        { strategy: 'subscriptions', text: 't(Contacts with active subscriptions to this service)', component: Subscriptions, placeholder: 't(Choose Subscriptions)' }
      ] : webinar ? [
        { strategy: 'participants', text: 't(Contacts who registered for this webinar)', component: Participants, placeholder: 't(Choose Participants)' }
      ] : team.id === 41 ? [
        { strategy: 'truevail', text: 't(Contacts from a Truevail team)', component: Truevail, placeholder: 't(Choose Criteria)' }
      ] : [],
      { strategy: 'criteria', text: 't(Contacts that meet custom criteria)', component: Picker, placeholder: 't(Choose Criteria)' },
      { strategy: 'filter', text: 't(Contacts from a saved filter)', component: Filter, placeholder: 't(Choose Filter)' },
      { strategy: 'list', text: 't(Contacts subscribed to a list)', component: List, placeholder: 't(Choose List)' },
      { strategy: 'contacts', text: 't(Specific contacts)', component: Contacts, placeholder: 't(Choose Contacts)' }
    ]
  }

  _handleChange() {
    const { dont_send_strategy, dont_send_config, engagement, send_strategy, send_config, total_recipients } = this.state
    const { channel, purpose } = this.props
    this.props.onChange({
      dont_send_strategy,
      dont_send_config,
      ...channel === 'email' && purpose === 'marketing' ? { engagement } : {},
      send_strategy,
      send_config,
      total_recipients
    })
  }

  _handleClear(key) {
    this.setState({
      [`${key}_config`]: {}
    })
  }

  _handleEnagement(engagement) {
    this.setState({ engagement })
  }

  _handleFetch() {
    const { send_config } = this.state
    if(!send_config) return
    this.context.network.request({
      endpoint: '/api/admin/crm/recipients/totals',
      method: 'GET',
      query: this._getQuery(),
      onSuccess: ({ data }) => {
        this.setState(data)
      }
    })
  }

  _handlePicker(key) {
    const strategy = this.state[`${key}_strategy`]
    if(!strategy || strategy === 'none') return
    const strategies = this._getStrategies()
    const chosen = _.find(strategies, { strategy })
    if(!chosen) return
    this.context.form.push(<chosen.component { ...this._getPicker(key) } />)
  }

  _handleSet(value) {
    this.setState({
      send_strategy: 'criteria',
      ...value
    })
  }

  _handleStrategy(key, strategy) {
    if(strategy === this.state[`${key}_strategy`]) return
    const strategies = this._getStrategies()
    const chosen = strategy ? _.find(strategies, { strategy }) || {} : {}
    this.setState({
      [`${key}_strategy`]: chosen.strategy || null,
      [`${key}_config`]: null,
      [`${key}_recipients`]: 0
    })
  }

  _handleUpdate(key, value) {
    this.setState({
      [`${key}_config`]: value
    })
  }

  _handleValidate() {
    const { dont_send_config, dont_send_strategy, engagement, send_config, send_strategy, total_recipients } = this.state
    const { required, onValid } = this.props
    if(required && (!total_recipients || total_recipients.length === 0)) {
      return onValid(null, ['You must choose at least 1 recipient'])
    }
    onValid({
      dont_send_config,
      dont_send_strategy,
      engagement,
      send_config,
      send_strategy,
      total_recipients
    })
  }

}

export default ToField
