const badges = [
  { 
    title: 't(Truevail Admin)', 
    weight: 10, 
    access: {
      rights: { $allOf: ['truevail:access_admin'] }
    },
    icon: 'plane', 
    route: '/truevail/truevail/admin', 
    visible: ['desktop','tablet'], 
    tooltip: 't(Truevail Admin)'
  }
]

export default badges
