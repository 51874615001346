import SocialCampaignToken from '@apps/truevail/admin/tokens/social_campaign'
import Panel from '@admin/components/panel'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Social Campaigns)',
  collection: {
    endpoint: '/api/admin/truevail/agency/social_performances',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Social Campaign)', key: 'title', primary: true, format: (social_campaign) => (
        <SocialCampaignToken social_campaign={ social_campaign } show_provider={ false } show_tags={ true } show_voice={ true } />
      ) },
      { label: 't(Impressions)', key: 'impressions', collapsing: true, align: 'right' },
      { label: 't(Engagement)', key: 'engagement', collapsing: true, align: 'right' },
      { label: 't(Reach)', key: 'reach', collapsing: true, align: 'right' },
      { label: 't(Video Views)', key: 'video_views', collapsing: true, align: 'right' },
      { label: 't(Reactions)', key: 'reactions', collapsing: true, align: 'right'},
      { label: 't(Comments)', key: 'comments', collapsing: true, align: 'right' },
      { label: 't(Shares)', key: 'shares', collapsing: true, align: 'right' },
      { label: 't(Saves)', key: 'saves', collapsing: true, align: 'right' }
    ],
    defaultSort: { key: 'created_at', order: 'desc' },
    empty: {
      icon: 'envelope',
      title: 't(No Social Campaigns)',
      text: 't(You have not yet posted any social campaigns)'
    },
    entity: 'social campaign',
    onClick: (record) => context.router.push(`/admin/truevail/agency/social_performance/${record.id}`)
  }
})

export default Panel(null, mapPropsToPanel)
