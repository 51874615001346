import TruevailSignature from './truevail_signature'
import AddressBook from './address_book'
import Unsubscribe from './unsubscribe'
import Countdown from './countdown'
import Forward from './forward'
import Divider from './divider'
import Address from './address'
import WebView from './webview'
import Follow from './follow'
import Button from './button'
import Share from './share'
import Image from './image'
import Video from './video'
import Quote from './quote'
import Text from './text'
import Icon from './icon'
import Map from './map'

const blocks = [
  { type: 'address', label: 't(Address)', form: Address, noun_project_id: 309663 },
  { type: 'address_book', label: 't(Address Book)', form: AddressBook, noun_project_id: 309668 },
  { type: 'button', label: 't(Button)', form: Button, noun_project_id: 309746 },
  { type: 'countdown', label: 't(Countdown)', form: Countdown, noun_project_id: 309866 },
  { type: 'divider', label: 't(Divider)', form: Divider, noun_project_id: 1606276 },
  { type: 'forward', label: 't(Forward to Friend)', form: Forward, noun_project_id: 309760 },
  { type: 'icon', label: 't(Icon)', form: Icon, noun_project_id: 310630 },
  { type: 'image', label: 't(Image)', form: Image, noun_project_id: 309601 },
  { type: 'map', label: 't(Map)', form: Map, noun_project_id: 310870 },
  { type: 'follow', label: 't(Social Follow)', form: Follow, social: 'facebook' },
  { type: 'share', label: 't(Social Share)', form: Share, noun_project_id: 311082 },
  { type: 'text', label: 't(Text)', form: Text, noun_project_id: 310909 },
  { type: 'truevail_signature', label: 't(Truevail Signature)', form: TruevailSignature, noun_project_id: 309663, access: {
    'team.id': { $eq: 41 }
  } },
  { type: 'unsubscribe', label: 't(Unsubscribe)', form: Unsubscribe, noun_project_id: 309824 },
  { type: 'video', label: 't(Video)', form: Video, noun_project_id: 309684 },
  { type: 'webview', label: 't(Web View)', form: WebView, noun_project_id: 311081 }

]

export default blocks
