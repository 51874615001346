import UserStatusToken from '@apps/truevail/admin/tokens/user_status'
import Panel from '@admin/components/panel'
import UserToken from '@admin/tokens/user'
import React from 'react'
import New from './new'

const mapPropsToPanel = (props, context) => ({
  title: 't(Users)',
  collection: {
    endpoint: `/api/admin/truevail/admin/advisors/${props.advisor.id}/users`,
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Name)', key: 'last_name', primary: true, format: (user) => (
        <UserToken { ...user } show_email={ true } />
      ) },
      { label: 't(Activated)', key: 'account.activation_sent_at', primary: true, collapsing: true, format: UserStatusToken }
    ],
    defaultSort: { key: 'last_name', order: 'asc' },
    empty: {
      icon: 'user',
      title: 't(No Users)',
      text: 't(You have not yet created any users for this advisor)',
      buttons: [
        { 
          label: 'Add User', 
          modal: <New advisor={ props.advisor } /> 
        }
      ]
    },
    recordTasks: (record) => [
      {
        label: 't(Activate User)',
        show: record.account.activated_at !== null && !record.is_active,
        request: {
          endpoint: `/api/admin/truevail/admin/advisors/${props.advisor.id}/users/${record.id}/enable`,
          method: 'PATCH',
          body: {
            is_active: true
          },
          onFailure: (result) => context.flash.set('error', 't(Unable to activate user)')
        }
      },
      {
        label: 't(Deactivate User)',
        show: record.account.activated_at !== null && record.is_active,
        request: {
          endpoint: `/api/admin/truevail/admin/advisors/${props.advisor.id}/users/${record.id}/enable`,
          method: 'PATCH',
          body: {
            is_active: false
          },
          onFailure: (result) => context.flash.set('error', 't(Unable to deactivate user)')
        }
      },
      {
        label: 't(Unblock User)',
        show: record.is_blocked,
        request: {
          method: 'PATCH',
          endpoint: `/api/admin/truevail/admin/advisors/${props.advisor.id}/users/${record.id}/unblock`,
          onFailure: (reult) => context.flash.set('error', 't(Unable to unblock user)'),
          onSuccess: () => context.flash.set('success', 't(The user has been unblocked)')
        }
      },
      {
        label: 't(Send Password Reset)',
        show: record.account.activated_at !== null,
        request: {
          method: 'PATCH',
          endpoint: `/api/admin/truevail/admin/advisors/${props.advisor.id}/users/${record.id}/reset`,
          onFailure: () => context.flash.set('error', 't(Unable to send reset email)'),
          onSuccess: () => context.flash.set('success', 't(Email queued for delivery)')
        }
      },
      {
        label: 't(Send Activation Email)',
        show: record.account.activated_at === null,
        request: {
          method: 'PATCH',
          endpoint: `/api/admin/truevail/admin/advisors/${props.advisor.id}/users/${record.id}/activate`,
          onFailure: (result) => context.flash.set('error', 't(Unable to send activation email)'),
          onSuccess: (result) => context.flash.set('success', 't(Email queued for delivery)')
        }
      }
    ],
    entity: 'user'
  },
  tasks : {
    icon: 'plus',
    items: [
      { 
        label: 'Add User', 
        modal: <New advisor={ props.advisor } /> 
      }
    ]
  }
})

export default Panel(null, mapPropsToPanel)
