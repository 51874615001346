import PropTypes from 'prop-types'
import pluralize from 'pluralize'
import moment from 'moment'
import React from 'react'

const LastActiveToken = ({ last_active_at }) => {

  const last = last_active_at

  const status = _getStatus(last)

  return (
    <span>
      { status }
    </span>
  )

}

LastActiveToken.propTypes = {
  last_active_at: PropTypes.string
}

const _getStatus = (last_active_at) => {
  const diff = moment().diff(moment(last_active_at), 'minutes')
  if(!last_active_at) return 'NEVER'
  if(diff > 1440) return moment(last_active_at).format('MM/DD/YY @ hh:mm A')
  if(diff > 60) return pluralize('hours', Math.floor(diff / 60), true) + ' ago'
  if(diff > 15) return pluralize('minutes', diff, true) + ' ago'
  if(diff > 10) return moment(last_active_at).fromNow()
  if(diff > 5) return moment(last_active_at).fromNow()
  return 'ONLINE NOW'
}

export default LastActiveToken
