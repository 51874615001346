import Page from '@admin/components/page'
import Overview from './overview'
import Contacts from './contacts'
import React from 'react'

const mapPropsToPage = (props, context, resources, page) => ({
  title: resources.import.id,
  manager: {
    path: `/admin/crm/imports/${resources.import.id}`,
    items: [
      {
        label: 't(Overview)',
        panel: <Overview import={ resources.import } />
      },
      {
        label: 't(New Contacts)',
        path: '/new',
        panel: <Contacts import={ resources.import } type="new" />
      },
      {
        label: 't(Merged Contacts)',
        path: '/new',
        panel: <Contacts import={ resources.import } type="merged" />
      }
    ]
  }
})

const mapResourcesToPage = (props, context) => ({
  import: `/api/admin/crm_contacts/imports/${props.params.id}`
})

export default Page(mapResourcesToPage, mapPropsToPage)
