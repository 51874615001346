import React, { useState, useEffect } from 'react'
import StackRouter from './stack_router'
import qs from 'qs'

import { useRouterContext } from '@admin/components/router'

const StackRouterContainer = (props) => {

  const { router } = useRouterContext()
  const [shouldMount, setShouldMount] = useState(false)

  //adding a delay to let router update before rendering
  useEffect(() => {
    const timer = setTimeout(() => {
      setShouldMount(true)
    }, 100)
    return () => clearTimeout(timer)
  }, [100])

  const mergedProps = {
    router,
    action: router.action,
    pathname: router.history.slice(-1)[0].pathname,
    query: qs.parse(router.history.slice(-1)[0].search.slice(1)),
    ...props
  }

  return  shouldMount ? <StackRouter { ...mergedProps } /> : null

}

StackRouterContainer.propTypes = {}

export default StackRouterContainer
