import TeamToken from '@admin/tokens/team'
import Page from '@admin/components/page'

const mapPropsToPage = (props, context) => ({
  title: 't(Subscriptions)',
  access: { rights: { $allOf: ['dashboards:access_app','truevail:access_app'] } },
  collection: {
    endpoint: '/api/admin/dashboards/truevail/statuses',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Team)', key: 'team.title', sort: 'team', primary: true, format: TeamToken }
    ],
    defaultSort: { key: 'title', order: 'asc' },
    empty: {
      icon: 'check-square',
      title: 't(No Signups)',
      text: 't(No one has signed up yet)'
    },
    entity: 'team',
    onClick: (record) => {
      if(record.entity.type === 'agency') context.router.push(`/admin/truevail/admin/agencies/${record.entity.agency_id}`)
      if(record.entity.type === 'advisor') context.router.push(`/admin/truevail/admin/advisors/${record.entity.advisor_id}`)
      if(record.entity.type === 'supplier') context.router.push(`/admin/truevail/admin/suppliers/${record.entity.supplier_id}`)      
    }
  }
})

export default Page(null, mapPropsToPage)
