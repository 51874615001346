import ProgramForm from '@apps/crm/admin/components/programform'
import ImportToken from '@apps/maha/admin/tokens/import'
import StatusToken from '@admin/tokens/status'
import Page from '@admin/components/page'
import New from './new'
import React from 'react'

const mapPropsToPage = (props, context) => ({
  title: 't(Imports)',
  access: { rights: { $oneOf: ['crm:view_contacts','crm:manage_contacts'] } },
  collection: {
    endpoint: '/api/admin/crm_contacts/imports',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Import)', key: 'created_at', primary: true, format: ImportToken },
      { label: 't(New)', key: 'created_count', collapsing: true, align: 'right' },
      { label: 't(Merged)', key: 'merged_count', collapsing: true, align: 'right' },
      { label: 't(Status)', key: 'status', collapsing: true, primary: true, format: StatusToken }
    ],
    empty: {
      title: 't(No Imports)',
      text: 't(You have not yet created any lists)',
      icon: 'th-list',
      buttons: [
        { 
          label: 't(Create List)',
          access: { rights: { $oneOf: ['crm:manage_lists'] } },
          modal: <ProgramForm form={ New } />
        }
      ]
    },
    entity: 'import',
    defaultSort: { key: 'created_at', order: 'desc' },
    onClick: (record) => context.router.push(`/admin/crm/imports/${record.id}`)
  },
  task: {
    icon: 'plus',
    tooltip: 't(Create List)',
    access: { rights: { $oneOf: ['crm:manage_lists'] } },
    modal: <ProgramForm form={ New } />
  }
})

export default Page(null, mapPropsToPage)
