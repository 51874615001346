import MJSONScreenshot from '@admin/components/mjson_screenshot'
import VoiceToken from '@apps/truevail/admin/tokens/voice'
import PropTypes from 'prop-types'
import React from 'react'

const getScreenshot = (entity) => ({
  engine: 'mjson',
  entity: `truevail_social_campaign_variants/${entity.code}`,
  width: 50,
  height: 38,
  screenshoted_at: entity.screenshoted_at
})

const TruevailSocialCampaignToken = ({ social_campaign }) => (
  <div className="campaign-token compact">
    <div className="campaign-token-preview">
      <div className="campaign-token-preview-inner">
        <MJSONScreenshot { ...getScreenshot(social_campaign.variants[0]) } />
      </div>
    </div>
    <div className="campaign-token-label">
      <div className="token-link">
        { social_campaign.type.toUpperCase() }: { social_campaign.title }
      </div>
      <div className="token-stats">
        <VoiceToken perspective={ social_campaign.perspective } language={ social_campaign.language } />
      </div>
    </div>
    <div className="campaign-token-metric">
      { social_campaign.metric }
    </div>
  </div>
)

TruevailSocialCampaignToken.propTypes = {
  social_campaign: PropTypes.object
}

export default TruevailSocialCampaignToken
