import LastActiveToken from '@admin/tokens/last_active'
import AccountToken from '@admin/tokens/account'
import Panel from '@admin/components/panel'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Sessions)',
  rights: ['team:manage_users'],
  collection: {
    endpoint: '/api/admin/team/sessions',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(User)', key: 'user', format: (session) => (
        <AccountToken { ...session.account } presence={ false } />
      ), primary: true },
      { label: 't(Device)', key: 'device.device_type', sort: 'device_types.text' },
      { label: 'OS', key: 'device.os_name', sort: 'os_names.text' },
      { label: 'Browser', key: 'device.browser_name', sort: 'browser_names.text' },
      { label: 'Last Active', key: 'last_active_at', format: LastActiveToken, primary: true }
    ],
    criteria: {
      fields: [
        { label: 'item', fields: [
          { name: 'Device Type', key: 'maha_devices.device_type_id', type: 'select', endpoint: '/api/admin/team/device_values/device_types', valueKey: 'id', textKey: 'text', sort: { key: 'text', order: 'asc' } },
          { name: 'OS', key: 'maha_devices.os_name_id', type: 'select', endpoint: '/api/admin/team/device_values/os_names', valueKey: 'id', textKey: 'text', sort: { key: 'text', order: 'asc' } },
          { name: 'Browser', key: 'maha_devices.browser_name_id', type: 'select', endpoint: '/api/admin/team/device_values/browser_names', valueKey: 'id', textKey: 'text', sort: { key: 'text', order: 'asc' } }
        ] }
      ]
    },
    defaultSort: { key: 'last_active_at', order: 'desc' }
  }
})

export default Panel(null, mapPropsToPanel)
