import EmailStrategyToken from './email_strategy'
import Button from '@admin/components/button'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Details extends React.PureComponent {

  static propTypes = {
    formdata: PropTypes.object,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onNext: PropTypes.func,
    onSave: PropTypes.func
  }

  formRef = React.createRef()

  _handleChange = this._handleChange.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  constructor(props) {
    super(props)
    this.state = {
      config: props.formdata || {}
    }
  }

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { config } = this.state
    return {
      ref: this.formRef,
      showHeader: false,
      buttons: [
        { 
          label: 't(Previous)',
          color: 'blue',
          basic: true,
          disabled: true
        },
        { 
          label: config.email_strategy === 'optin' ? 't(Next)' : 't(Done)',
          color: 'blue',
          handler: this._handleSubmit 
        }
      ],
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            {
              label: 't(Email Newsletters)',
              instructions: 't(Opt-in or Opt-out of the done-for-you email newsletter campaign service)',
              name: 'email_strategy',
              type: 'radiogroup',
              options: ['optin','optout'],
              defaultValue: config.email_strategy || 'optin',
              format: EmailStrategyToken,
              required: true 
            },
            { 
              label: 't(Contact List)',
              instructions: 't(Provide your contact list to be subscribed to your email newsletter list) (CSV, XLS, or XSLX)',
              name: 'email_contacts_id',
              type: 'attachmentfield',
              prompt: 'Upload Contact List',
              allow: { extensions: ['csv','xls','xlsx'] },
              multiple: false,
              defaultValue: config.email_contacts_id  
            },
            {
              label: 't(Clean List Policy)',
              type: 'segment',
              required: true,
              fields: [
                { 
                  instructions: (
                    <>
                      To maintain high deliverability standards and keep our platform spam-free, all uploaded contact lists are 
                      automatically reviewed. If more than 5% of the emails on your list are invalid, or if a campaign exceeds
                      a 5% bounce rate or 1% spam complaint rate, your account may be suspended. Purchased, outdated, or
                      non-permission-based lists are not supported, so please ensure your list is clean and permission-based. <Button { ...this._getKb() } />
                      <br /><br />
                    </>
                  ),
                  name: 'email_suspension_confirmation',
                  prompt: 't(I agree to follow these list standards and verify that my contact list meets the requirements outlined above)',
                  type: 'checkbox',
                  defaultValue: config.email_suspension_confirmation,
                  required: true
                }
              ]
            }
          ]
        }
      ]
    }
  }

  _getKb() {
    return {
      label: 't(Learn more about our automated verification process in our Knowledge Base)',
      className: 'link',
      link: 'https://help.truevail.com/article/292-bounce-rate-policy'
    }
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleSuccess(config) {
    const { formdata, onNext, onSave } = this.props
    const action = config.email_strategy === 'optin' ? onNext : onSave
    action({
      ...formdata,
      ...config
    })
  }

}

export default Details
