import Invoice from '@apps/finance/admin/components/invoice'
import Page from '@admin/components/page'
import Payment from './payment'
import React from 'react'

const mapPropsToPage = (props, context, resources, page) => ({
  title: 't(Invoice)',
  tabs: {
    items: [
      { label: 't(Overview)', component: <Invoice invoice={ resources.invoice } payments={ resources.invoice.payments } /> }
    ]
  },
  tasks: {
    items: [
      ...resources.invoice.status !== 'paid' ? [
        { 
          label: 't(Make Payment)', 
          modal: <Payment invoice_id={ resources.invoice.id } /> 
        }
      ] : [],
      { 
        label: 't(Download Invoice)', 
        url: `${context.provider.admin_host}/finance/invoices/${resources.invoice.code}/download` 
      }
    ]
  }
})

const mapResourcesToPage = (props, context) => ({
  invoice: `/api/admin/team/invoices/${props.params.id}`
})

export default Page(mapResourcesToPage, mapPropsToPage)
