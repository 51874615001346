import DeliverabilityRate from '@admin/tokens/deliverability_rate'
import Panel from '@admin/components/panel'
import moment from 'moment'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Sent)',
  collection: {
    endpoint: '/api/admin/truevail/admin/processings',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Day)', key: 'processed_at', primary: true, format: (record) => (
        <div className="token">
          { moment(record.processed_at).tz(context.admin.account.timezone).format('YYYY-MM-DD') }
        </div>
      ) },
      { label: 't(Sent)', key: 'sent_count', collapsing: true, align: 'right' },
      { label: 't(Open)', key: 'open_rate', collapsing: true, align: 'right', format: (domain) => (
        <DeliverabilityRate value={ domain.open_rate } />
      ) },
      { label: 't(Click)', key: 'click_rate', collapsing: true, align: 'right', format: (domain) => (
        <DeliverabilityRate value={ domain.click_rate } />
      ) },
      { label: 't(Bounce)', key: 'bounce_rate', collapsing: true, align: 'right', format: (domain) => (
        <DeliverabilityRate value={ domain.bounce_rate } highlight={ domain.bounce_rate >= 0.05 } />
      ) },
      { label: 't(Soft Bounce)', key: 'soft_bounce_rate', collapsing: true, align: 'right', format: (domain) => (
        <DeliverabilityRate value={ domain.soft_bounce_rate } highlight={ domain.soft_bounce_rate >= 0.05 } />
      ) },
      { label: 't(Hard Bounce)', key: 'hard_bounce_rate', collapsing: true, align: 'right', format: (domain) => (
        <DeliverabilityRate value={ domain.hard_bounce_rate } highlight={ domain.hard_bounce_rate >= 0.05 } />
      ) },
      { label: 't(Complain)', key: 'complain_rate', collapsing: true, align: 'right', format: (domain) => (
        <DeliverabilityRate value={ domain.complain_rate } highlight={ domain.complain_rate >= 0.001 } />
      ) },
      { label: 't(Unsubscribe)', key: 'unsubscribe_rate', collapsing: true, align: 'right', format: (domain) => (
        <DeliverabilityRate value={ domain.unsubscribe_rate } />
      ) }
    ],
    defaultQuery: { 
      interval: 'day',
      domain_id: props.domain.id
    },
    defaultSort: { key: 'processed_at', order: 'desc' },
    empty: {
      icon: 'envelope',
      title: 't(No Emails)',
      text: 't(There are no sent emails for this domain)'
    },
    entity: 'day',
    onClick: (record) => context.router.push(`/admin/truevail/admin/sent/domains/${props.domain.id}/day/${moment(record.processed_at).format('YYYY-MM-DD')}`)
  }
})

export default Panel(null, mapPropsToPanel)
