import { Responsive, WidthProvider } from 'react-grid-layout'
import Message from '@admin/components/message'
import NewCard from '../../cards/new'
import Card from '../../cards/show'
import PropTypes from 'prop-types'
import Toolbar from './toolbar'
import React from 'react'

const GridLayout = WidthProvider(Responsive)

class Grid extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    network: PropTypes.object
  }

  static propTypes = {
    card_types: PropTypes.array,
    cards: PropTypes.array,
    daterange: PropTypes.object,
    panel: PropTypes.object,
    onDateRange: PropTypes.func,
    onLayout: PropTypes.func,
    onToggle: PropTypes.func
  }

  _handleLayout = this._handleLayout.bind(this)

  render() {
    const { cards } = this.props
    return (
      <div className="maha-dashboard-panel-grid">
        <div className="maha-dashboard-panel-grid-header">
          <Toolbar { ...this._getToolbar() } />
        </div>
        <div className="maha-dashboard-panel-grid-body">
          { cards.length > 0 ?
            <GridLayout { ...this._getGridLayout() }>
              { cards.map((card, index) => (
                <div className='maha-dashboard-panel-grid-item' key={`card_${card.id}`}>
                  <Card { ...this._getCard(card, index, false) } />
                </div>
              )) }
            </GridLayout> :
            <Message { ...this._getEmpty() } />
          }
        </div>
      </div>
    )
  }

  _getCard(card, index, isExpanded) {
    const { daterange, panel } = this.props
    return {
      card,
      daterange,
      index,
      panel,
      isExpanded,
      showChart: _.includes(card.config.elements, 'chart') || isExpanded,
      showTable: _.includes(card.config.elements, 'table') || isExpanded,
      onToggle: this._handleToggle.bind(this, card.id)
    }
  }

  _getEmpty() {
    const { card_types, panel } = this.props
    return {
      icon: 'table',
      title: 't(Dashboard Panel)',
      text: 't(This is a dashboard panel. Panels let you add and arrange cards from any app or program to gain insights into activity and/or performance.)',
      buttons: [
        { 
          label: 't(Add Card)', 
          access: { rights: { $oneOf: ['dashboards:manage_panels'] } },
          modal: <NewCard card_types={ card_types } panel={ panel } /> 
        }
      ]
    }
  }

  _getGridLayout() {
    const { admin } = this.context
    const { panel } = this.props
    const cards = this.props.cards.sort((a,b) => {
      return ((36 * a.layout.y) + a.layout.x) > ((36 * b.layout.y) + b.layout.x) ? 1 : -1 
    })
    return {
      className: 'maha-dashboard-panel-grid-layout',
      layouts: {
        desktop: cards.map((card) => ({
          i: `card_${card.id}`, 
          ...card.layout,
          minW: 4,
          maxW: 36,
          minH: 1,
          maxH: 3,
          static: panel.owner.id !== admin.user.id
        })),
        mobile: cards.sort().map((card, index) => ({
          i: `card_${card.id}`, 
          x: 0,
          y: cards.slice(0, index).reduce((total, card) => total + card.layout.h, 0),
          w: 1,
          h: card.layout.h,
          static: true
        }))
      },
      resizeHandles: ['ne','nw','sw','se'],
      maxRows: Infinity,
      rowHeight: 100,
      breakpoints: { desktop: 768, mobile: 0 },
      cols: { desktop: 36, mobile: 1 },
      onLayoutChange: this._handleLayout
    }
  }

  _getToolbar() {
    const { card_types, daterange, panel, onDateRange } = this.props
    return {
      card_types,
      daterange,
      panel,
      onDateRange
    }
  }

  _handleLayout(currentLayout, allLayouts) {
    if(!_.isEqual(allLayouts.desktop, currentLayout )) return
    this.props.onLayout(currentLayout.map(item => ({
      id: parseInt(item.i.replace('card_', '')),
      layout: { x: item.x, y: item.y, w: item.w, h: item.h }
    })))
  }

  _handleToggle(id) {
    this.props.onToggle(id)
  }

}

export default Grid
