import ModalPanel from '@admin/components/modal_panel'
import Results from '@admin/components/import/results'
import Loader from '@admin/components/loader'
import PropTypes from 'prop-types'
import Chooser from './chooser'
import React from 'react'

class Preview extends React.PureComponent {

  static contextTypes = {
    network: PropTypes.object
  }

  static propTypes = {
    _import: PropTypes.object,
    asset: PropTypes.object,
    object_type: PropTypes.string,
    onBack: PropTypes.func,
    onDone: PropTypes.func
  }

  state = {
    delimiter: ',',
    headers: true,
    index: 0,
    parsed: null
  }

  _handleBack = this._handleBack.bind(this)
  _handleDone = this._handleDone.bind(this)
  _handleFetch = this._handleFetch.bind(this)
  _handleIndex = this._handleIndex.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    if(!this.state.parsed) return <Loader />
    const { asset } = this.props
    return (
      <ModalPanel { ...this._getPanel() }>
        <div className="maha-import-preview">
          <div className="maha-import-preview-header">
            { !asset.file_name.match(/xls/) &&
              <div className="maha-import-preview-header-item">
                <Chooser { ...this._getDelimiter() } />
              </div>
            }
            <div className="maha-import-preview-header-item">
              <Chooser { ...this._getHeaders() } />
            </div>
          </div>
          <Results { ...this._getResults() } />
        </div>
      </ModalPanel>
    )
  }

  componentDidMount() {
    this._handleMatch()
    this._handleFetch()
  }

  componentDidUpdate(prevProps, prevState) {
    const { delimiter, headers, index } = this.state
    if(delimiter !== prevState.delimiter) {
      this._handleFetch()
    }
    if(headers !== prevState.headers) {
      this._handleFetch()
    }
    if(index !== prevState.index) {
      this._handleFetch()
    }
  }

  _getCurrent() {
    const { parsed } = this.state
    return parsed ? parsed.headers.map((key, i) => ({
      key: _.isNil(key) ? 't(NO HEADER)' : key,
      value: parsed.row[i]
    })) : {}
  }

  _getDelimiter() {
    const { delimiter } = this.state
    return {
      defaultValue: delimiter,
      options: [
        { key: ',', value: 'Comma' },
        { key: '\t', value: 'Tab' },
        { key: ':', value: 'Colon' },
        { key: '|', value: 'Pipe' }
      ],
      onChange: this._handleChange.bind(this, 'delimiter')
    }
  }

  _getHeaders() {
    const { headers } = this.state
    return {
      defaultValue: headers,
      options: [
        { key: true, value: 'Headers' },
        { key: false, value: 'No Headers' }
      ],
      onChange: this._handleChange.bind(this, 'headers')
    }
  }

  _getPanel() {
    return {
      title: 't(Preview Data)',
      leftItems: [
        { icon: 'chevron-left', handler: this._handleBack }
      ],
      rightItems: [
        { label: 't(Next)', handler: this._handleDone }
      ]
    }
  }

  _getResults() {
    const record = this._getCurrent()
    const { index, parsed } = this.state
    return {
      record,
      index,
      total: parsed ? parsed.rows_count : 0,
      onChange: this._handleIndex
    }
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleChange(key, value) {
    this.setState({
      [key]: value,
      index: 0
    })
  }

  _handleDone() {
    const { parsed, delimiter, headers } = this.state
    const { _import, asset } = this.props
    this.props.onDone({
      ..._import,
      asset_id: asset.id,
      service: 'excel', 
      config: {
        ..._import.config,
        name: asset.original_file_name,
        rows_count: parsed.rows_count,
        delimiter,
        headers
      }
    }, parsed)
  }

  _handleFetch() {
    const { delimiter, headers, index } = this.state
    const { asset, object_type } = this.props
    this.context.network.request({
      endpoint: `/api/admin/${object_type}/imports/preview`,
      method: 'GET',
      query: {
        asset_id: asset.id,
        delimiter,
        headers,
        index
      },
      onSuccess: this._handleSuccess
    })
  }

  _handleIndex(index) {
    this.setState({ index })
  }

  _handleMatch() {
    const { asset } = this.props
    if(asset.file_name.match(/\.csv$/)) {
      this.setState({ delimiter: ',' })
    } else if(asset.file_name.match(/\.tsv$/)) {
      this.setState({ delimiter: '\t' })
    }
  }

  _handleSuccess(result) {
    this.setState({
      parsed: result.data
    })
  }

}

export default Preview
