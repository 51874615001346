import ContactToken from '@apps/crm/admin/tokens/contact'
import StatusToken from '@admin/tokens/status'
import Format from '@admin/components/format'
import Panel from '@admin/components/panel'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Participants)',
  collection: {
    endpoint: `/api/admin/truevail/admin/webinars/${props.webinar.id}/participants`,
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Contact)', key: 'contact.display_name', sort: 'last_name', primary: true, format: ({ contact }) => {
        return <ContactToken { ...contact } />
      } },
      { label: 't(Registered)', key: 'registered_at', collapsing: true, format: 'datetime' },
      { label: 't(Status)', key: 'status', collapsing: true, primary: true, format: StatusToken },
      { label: 't(Converted)', key: 'signup.created_at', sort: 'converted_at', collapsing: true, align: 'center', format: (demo) => (
        <Format value={ demo.signup !== null } format='check' />
      ) }
    ],
    criteria: {
      system: [
        { id: 0, title: 't(Attendees)', config: {
          criteria: [
            { code: 'abc', data: null, field: 'status', operator: '$eq', parent: null, value: 'attended' }
          ]
        } },
        { id: 0, title: 't(No Shows)', config: {
          criteria: [
            { code: 'abc', data: null, field: 'status', operator: '$eq', parent: null, value: 'noshow' }
          ]
        } }
      ],
      fields: [
        { label: 't(Participant)', fields: [
          { name: 'Status', key: 'status', type: 'select', options: ['registered','joined','noshow','attended'], format: StatusToken  }
        ] }
      ]
    },
    defaultSort: { key: 'registered_at', order: 'desc' },
    empty: {
      title: 't(No Participants)',
      text: 't(There are no registered participants for this webinar)',
      icon: 'user'
    },
    entity: 'participants',
    onClick: (record) => context.router.push(`/admin/truevail/admin/webinars/${props.webinar.id}/participants/${record.id}`)
  }
})

export default Panel(null, mapPropsToPanel)
