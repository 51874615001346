import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import moment from 'moment'
import React from 'react'

class Transition extends React.Component {

  static contextTypes = {
    modal: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    action: PropTypes.string,
    agency: PropTypes.object
  }

  state = {
    config: {}
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form {...this._getForm()} />
  }

  _getForm() {
    const { agency, action } = this.props
    const { config } = this.state
    return {
      ...action === 'cancel' ? {
        title: 't(Cancel Agency)'
      } : action === 'pause' ? {
        title: 't(Pause Agency)'
      } : action === 'activate' ? {
        title: 't(Activate Agency)'
      } : action === 'resume' ? {
        title: 't(Resume Agency)'
      } : action === 'reactivate' ? {
        title: 't(Reactivate Agency)'
      } : {},
      action: `/api/admin/truevail/admin/agencies/${agency.id}/transition`,
      method: 'PATCH',
      onCancel: this._handleCancel,
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { 
              name: 'action', 
              type: 'hidden', 
              value: action 
            },
            { 
              label: 't(Timing)', 
              type: 'segment', 
              fields: [
                { 
                  name: 'strategy', 
                  type: 'radiogroup', 
                  options: action === 'cancel' ? [
                    { value: 'now', text: 't(Cancel Immediately)' },
                    { value: 'schedule', text: 't(Schedule Cancellation)' }
                  ] : action === 'pause' ? [
                    { value: 'now', text: 't(Pause Immediately)' },
                    { value: 'schedule', text: 't(Schedule Pause)' }
                  ] : action === 'activate' ? [
                    { value: 'now', text: 't(Activate Immediately)' },
                    { value: 'schedule', text: 't(Schedule Activation)' }
                  ] : action === 'resume' ? [
                    { value: 'now', text: 't(Resume Immediately)' },
                    { value: 'schedule', text: 't(Schedule Resume)' }
                  ] : action === 'reactivate' ? [
                    { value: 'now', text: 't(Reactivate Immediately)' },
                    { value: 'schedule', text: 't(Schedule Reactivation)' }
                  ] : [], 
                  required: true, 
                  defaultValue: 'now' 
                },
                { 
                  name: 'date', 
                  type: 'datefield', 
                  required: true,
                  show: config.strategy === 'schedule',
                  defaultValue: moment().add(1,'day').format('YYYY-MM-DD')
                }
              ] 
            }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSuccess() {
    this.context.modal.close()
  }

}

export default Transition
