import AllowancesField from '@apps/subscriptions/admin/components/allowancesfield'
import FrequencyField from '@apps/subscriptions/admin/components/frequencyfield'
import RevenueTypeToken from '@apps/finance/admin/tokens/revenue_type'
import PlanTypeToken from '@apps/subscriptions/admin/tokens/plan_type'
import ProjectToken from '@apps/finance/admin/tokens/project'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class New extends React.Component {

  static contextTypes = {
    modal: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    service: PropTypes.object
  }

  state = {
    config: {}
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form {...this._getForm()} />
  }

  _getForm() {
    const { config } = this.state
    const { service } = this.props
    return {
      title: 't(New Plan)',
      method: 'POST',
      action: `/api/admin/subscriptions/services/${service.id}/plans`,
      onCancel: this._handleCancel,
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Title)', name: 'title', type: 'textfield', required: true },
            { label: 't(Line Item Description)', name: 'line_item_description', type: 'textfield', required: true },
            { label: 't(Pricing)', type: 'segment', fields: [
              { name: 'type', type: 'radiogroup', options: ['paid','free'], required: true, defaultValue: 'paid', format: PlanTypeToken },
              ...config.type === 'paid' ? [
                { type: 'fields', fields: [
                  { label: 't(Project)', name: 'project_id', type: 'lookup', endpoint: '/api/admin/finance/projects', valueKey: 'id', textKey: 'display', required: true, format: ProjectToken },
                  { label: 't(Revenue Type)', name: 'revenue_type_id', type: 'lookup', endpoint: '/api/admin/finance/revenue_types', valueKey: 'id', textKey: 'display', required: true, format: RevenueTypeToken }
                ] },
                { label: 't(Base Price)', name: 'price', type: 'moneyfield', required: true }
              ] : []
            ] },
            ...service.model === 'metered' ? [
              { label: 't(Resource Allowances)', name: 'allowances', type: AllowancesField, service, plan: config, required: true }
            ] : [],
            { label: 't(Renewal Frequency)', name: 'frequency', type: FrequencyField, required: true, defaultValue: service.frequency }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSuccess(plan) {
    const { service } = this.props
    this.context.router.push(`/subscriptions/services/${service.id}/plans/${plan.id}`)
    this.context.modal.close()
  }

}

export default New
