import PropTypes from 'prop-types'
import Chart from 'chart.js/auto'
import numeral from 'numeral'
import React from 'react'

class BarChart extends React.Component {

  static contextTypes = {
    locale: PropTypes.object
  }

  static propTypes = {
    datasets: PropTypes.array,
    options: PropTypes.object,
    type: PropTypes.string
  }

  canvasRef = React.createRef()
  chart = null
  ctx = null
  node = null

  render() {
    return <canvas ref={ this.canvasRef } />
  }

  componentDidMount() {
    this._handleInit()
  }

  componentDidUpdate(prevProps) {
    const { options, datasets } = this.props
    if(!_.isEqual(options, prevProps.options)) {
      this._handlePlot()
    }
    if(!_.isEqual(datasets, prevProps.datasets)) {
      this._handlePlot()
    }
  }

  _handleInit() {
    const { datasets, options } = this.props
    const { format, grid, stacked } = options
    const { locale } = this.context
    this.ctx = this.canvasRef.current.getContext('2d')
    this.chart = new Chart(this.ctx, {
      type: 'bar',
      data: {
        labels: datasets[0].points.map(r => r.x),
        datasets: datasets.map(dataset => ({
          label: locale.t(dataset.label),
          data: dataset.points.map(r => r.y),
          borderColor: dataset.color,
          backgroundColor: `${dataset.color}22`
        }))
      },
      options: {
        animation: {
          duration: 0
        },
        elements: {
          bar: {
            borderWidth: 2,
            fill: true
          }
        },
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            callbacks: {
              label: function(context) {
                const value = context.parsed.y
                return `${context.dataset.label}: ${format === 'currency' ? numeral(value).format('$0,0') : value}`
              }
            }
          }
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            display: true,
            stacked,
            ticks: {
              display: grid
            },
            grid: {
              display: grid
            }
          },
          y: {
            display: grid,
            stacked,
            beginAtZero: true,
            ...grid ? {
              ticks: {
                display: true,
                callback: function(value, index, values) {
                  return format === 'currency' ? numeral(value).format('$0,0') : value
                }
              },
              grid: {
                display: true
              }
            } : {}
          }
        }
      }
    })
  }

  _handlePlot() {
    const { datasets } = this.props
    if(!this.chart) this._handleInit()
    const { locale } = this.context
    this.chart.data = {
      labels: datasets[0].points.map(r => r.x),
      datasets: datasets.map(dataset => ({
        label: locale.t(dataset.label),
        data: dataset.points.map(r => r.y),
        borderColor: dataset.color,
        backgroundColor: `${dataset.color}22`
      }))
    }
    this.chart.update('none')
  }

}

export default BarChart
