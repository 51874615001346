import WorkflowToken from '@apps/automation/admin/tokens/workflow'
import New from '@apps/automation/admin/views/workflows/new'
import Panel from '@admin/components/panel'
import React from 'react'


const getNew = (props) => ({
  program_id: props.pipeline.program.id,
  triggers: [
    'sales:deal_created',
    'sales:deal_updated',
    'sales:deal_transitioned',
    'sales:deal_stage_duration'
  ],
  params: {
    pipeline_id: props.pipeline.id
  }
})

const mapPropsToPanel = (props, context) => ({
  title: 't(Workflows)',
  collection: {
    endpoint: '/api/admin/automation/workflows',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Title)', key: 'title', primary: true, format: (workflow) => (
        <WorkflowToken workflow={ workflow } compact={ true } />
      ) }
    ],
    empty: {
      title: 't(No Workflows)',
      text: 't(There are no workflows for this pipeline)',
      icon: 'sitemap',
      buttons: [
        { 
          label: 'Create Workflow', 
          access: {
            programs: { $canEdit: props.pipeline.program.id },
            rights: { $oneOf: ['automation:access_app','automation:manage_workflows','sales:manage_pipelines'] }
          },
          modal: <New { ...getNew(props) } /> 
        }
      ]
    },
    export: [
      { label: 't(ID)', key: 'id' },
      { label: 't(Title)', key: 'display_name' },
      { label: 't(Program)', key: 'program.title' },
      { label: 't(Enrolled)', key: 'enrolled_count' },
      { label: 't(Active)', key: 'active_count' },
      { label: 't(Lost)', key: 'lost_count' },
      { label: 't(Converted)', key: 'converted_count' },
      { label: 't(Completed)', key: 'completed_count' },
      { label: 't(Status)', key: 'status' }
    ],
    entity: 'worfklow',
    defaultQuery: { pipeline_id: props.pipeline.id },
    defaultSort: { key: 'created_at', order: 'desc' },
    onClick: (record) => context.router.push(`/automation/workflows/${record.id}`)
  },
  task: {
    icon: 'plus',
    access: {
      programs: { $canEdit: props.pipeline.program.id },
      rights: { $oneOf: ['automation:access_app','automation:manage_workflows','sales:manage_pipelines'] }
    },
    modal: <New { ...getNew(props) } />
  }
})

export default Panel(null, mapPropsToPanel)
