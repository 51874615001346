import ImportStrategyToken from '@admin/tokens/import_strategy'
import ListTypeToken from '@apps/crm/admin/tokens/list_type'
import ModalPanel from '@admin/components/modal_panel'
import Message from '@admin/components/message'
import Button from '@admin/components/button'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Configure extends React.PureComponent {

  static contextTypes = {
    modal: PropTypes.object,
    network: PropTypes.object
  }

  static propTypes = {
    _import: PropTypes.object,
    onBack: PropTypes.func,
    onDone: PropTypes.func
  }

  state = {
    agreed_to_warning: false
  }

  _handleAgree = this._handleAgree.bind(this)
  _handleBack = this._handleBack.bind(this)
  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    const { rows_count } = this.props._import.config
    const { agreed_to_warning } = this.state
    return rows_count > 100 && !agreed_to_warning ? (
      <ModalPanel {...this._getPanel() }>
        <Message { ...this._getWarning() } />
      </ModalPanel>
    ) : <Form { ...this._getForm() } />
  }

  _getForm() {
    const { _import } = this.props
    return {
      title: 't(Configure Import)',
      action: `/api/admin/${_import.object_type}/imports/${_import.id}`,
      method: 'PATCH',
      cancelIcon: 'chevron-left',
      saveText: 'Next',
      onCancel: this._handleBack,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { 
              label: 't(Duplicate Strategy)',
              instructions: 't(Your data may include contacts that already exit in the CRM. Using the contact\'s email address, we can detect these duplicates and handle them according to your desired duplicate strategy. Choose a duplicate strategy from the list below)',
              name: 'config.duplicate_strategy', type: 'radiogroup', options: ['overwrite','discard'], format: ImportStrategyToken, defaultValue: _import.config.duplicate_strategy || 'overwrite'
            },
            { label: 't(Consent)', type: 'segment', fields: [
              { name: 'config.channels', type: 'checkboxgroup', deselectable: false, options: ['email','voice','sms','postal'], valueKey: 'value', textKey: 'text', defualtValue: _import.config.channels },
              { name: 'config.restore_consent', type: 'checkbox', prompt: 't(Restore consent if contact already exists and was previously opted out)', defualtValue: _import.config.restore_consent || false }
            ], instructions: 't(Indicate the channels through which these contacts have given you explicit consent to contact them)' },
            { label: 't(Lists)', type: 'segment', fields: [
              { entity: 't(List)', name: 'config.lists', type: 'lookup', endpoint: `/api/admin/team/programs/${_import.program.id}/lists`, multiple: true, valueKey: null, compareKey: 'id', textKey: 'title', form: this._getListForm(), defualtValue: _import.config.list },
              { name: 'config.restore_subscription', type: 'checkbox', prompt: 't(Resubscribe if contact already exists and was previously unsubscribed)', defualtValue: _import.config.restore_subscription || false }
            ] },
            { label: 't(Tags)', name: 'config.tags', type: 'tagfield', endpoint: '/api/admin/tags', filter: { program_id: { $eq: _import.program.id} }, defualtValue: _import.config.tags }
          ]
        }
      ]
    }
  }

  _getListForm() {
    const { _import } = this.props
    return {
      title: 't(New List)',
      method: 'POST',
      action: '/api/admin/crm/lists',
      sections: [
        {
          fields: [
            { name: 'program_id', type: 'hidden', value: _import.program.id },
            { label: 't(Title)', name: 'title', type: 'textfield', required: true },
            { label: 't(Type)', name: 'type', type: 'radiogroup', options: ['private','public'], required: true, defaultValue: 'private', format: ListTypeToken }
          ]
        }
      ]
    }
  }

  _getPanel() {
    return {
      title: 't(Large List)',
      leftItems: [
        { icon: 'chevron-left', handler: this._handleBack }
      ]
    }
  }

  _getWarning() {
    const kb = {
      label: 'Learn more at our Knowledge Base',
      className: 'link',
      link: 'https://help.truevail.com/article/292-bounce-rate-policy'
    }
    return {
      icon: 'warning',
      title: 't(Contact List Verification)',
      text: (
        <>
          To maintain high deliverability standards and keep our platform spam-free, all uploaded contact lists are
          automatically reviewed. If more than 5% of the emails on your list are invalid, or if a campaign exceeds a
          5% bounce rate or 1% spam complaint rate, your account may be suspended. We dont support purchased or
          outdated lists, so ensure yours is clean and permission-based. <Button { ...kb } />.
        </>

      ),
      buttons: [
        { 
          label: 't(Cancel Import)', 
          handler: this._handleCancel
        },
        { 
          label: 't(I understand. Let\'s continue)', 
          handler: this._handleAgree 
        }
      ]
    }
  }

  _handleAgree() {
    this.setState({
      agreed_to_warning: true
    })
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess(_import) {
    const { lists } = _import.config
    this.props.onDone({
      ..._import,
      config: {
        ..._import.config,
        lists: lists ? lists.map(list => ({
          id: list.id,
          title: list.title
        })) : []
      }
    })
  }

}

export default Configure
