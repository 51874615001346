import BillingSubscriptions from './billing_subscriptions'
import SocialCampaigns from './social_campaigns'
import PaymentMethods from './payment_methods'
import EmailCampaigns from './email_campaigns'
import SocialBatches from './social_batches'
import Subscriptions from './subscriptions'
import Configuration from './configuration'
import SupportUsers from './support_users'
import Page from '@admin/components/page'
import Templates from './templates'
import Renewals from './renewals'
import Overview from './overview'
import Profiles from './profiles'
import Invoices from './invoices'
import Senders from './senders'
import Themes from './themes'
import Signup from './signup'
import Emails from './emails'
import Deals from './deals'
import Forms from './forms'
import Lists from './lists'
import Users from './users'
import React from 'react'

const mapPropsToPage = (props, context, resources, page) => ({
  title: resources.advisor.team.title,
  manager: {
    path: `/admin/truevail/admin/advisors/${resources.advisor.id}`,
    items: [
      {
        label: 't(Overview)',
        panel: <Overview advisor={ resources.advisor } />
      },
      {
        label: 't(Branding)',
        show: resources.advisor.team.is_active,
        route: `/admin/truevail/admin/advisors/${resources.advisor.id}/branding`
      },
      {
        label: 't(Configuration)',
        path: '/configuration',
        show: resources.advisor.team.is_active,
        panel: <Configuration advisor={ resources.advisor } />
      },
      {
        label: 't(Activities)',
        show: resources.advisor.team.is_active,
        children: [
          {
            label: 't(Deals)',
            path: '/deals',
            panel: <Deals contact={ resources.advisor.team.primary_user.provider_contact } />
          },
          {
            label: 't(Signup)',
            path: '/signup',
            show: resources.advisor.signup !== null,
            panel: <Signup advisor={ resources.advisor } />
          }
        ]
      },
      {
        label: 't(Communication)',
        show: resources.advisor.team.is_active,
        children: [
          {
            label: 't(Email)',
            path: '/email',
            panel: <Emails contact={ resources.advisor.team.primary_user.provider_contact } emailable="true" />
          }
        ]
      },
      {
        label: 't(Email)',
        show: resources.advisor.team.is_active,
        children: [
          {
            label: 't(Campaigns)',
            path: '/email_campaigns',
            panel: <EmailCampaigns advisor={ resources.advisor } />
          },
          {
            label: 't(Lists)',
            path: '/lists',
            panel: <Lists advisor={ resources.advisor } />
          },
          {
            label: 't(Senders)',
            path: '/senders',
            show: resources.advisor.team.is_active,
            panel: <Senders advisor={ resources.advisor } />
          },
          {
            label: 't(Templates)',
            path: '/templates',
            panel: <Templates advisor={ resources.advisor } />
          }
        ]
      },
      {
        label: 't(Finance)',
        children: [
          {
            label: 't(Billing Subscriptions)',
            path: '/billing_subscriptions',
            panel: <BillingSubscriptions advisor={ resources.advisor } />
          },
          {
            label: 't(Invoices)',
            path: '/invoies',
            panel: <Invoices contact={ resources.advisor.team.primary_user.provider_contact } />
          },
          {
            label: 't(Payment Methods)',
            path: '/payment_methods',
            panel: <PaymentMethods contact={ resources.advisor.team.primary_user.provider_contact } />
          },
          {
            label: 't(Renewals)',
            path: '/renewals',
            panel: <Renewals contact={ resources.advisor.team.primary_user.provider_contact } />
          }
        ]
      },
      {
        label: 't(Forms)',
        path: '/forms',
        show: resources.advisor.team.is_active,
        panel: <Forms advisor={ resources.advisor } />
      },
      {
        label: 't(Social Media)',
        show: resources.advisor.team.is_active,
        children: [
          {
            label: 't(Batches)',
            path: '/social_batches',
            panel: <SocialBatches advisor={ resources.advisor } />
          },
          {
            label: 't(Campaigns)',
            path: '/social_campaigns',
            panel: <SocialCampaigns advisor={ resources.advisor } />
          },
          {
            label: 't(Profiles)',
            path: '/profiles',
            panel: <Profiles advisor={ resources.advisor } />
          }
        ]
      },
      {
        label: 't(Subscriptions)',
        path: '/subscriptions',
        panel: <Subscriptions advisor={ resources.advisor } />
      },
      {
        label: 't(Support Users)',
        path: '/support_users',
        show: resources.advisor.team.is_active,
        panel: <SupportUsers advisor={ resources.advisor } />
      },
      {
        label: 't(Themes)',
        path: '/themes',
        show: resources.advisor.team.is_active,
        panel: <Themes advisor={ resources.advisor } />
      },
      {
        label: 't(Users)',
        path: '/users',
        show: resources.advisor.team.is_active,
        panel: <Users advisor={ resources.advisor } />
      }
    ]
  }
})

const mapResourcesToPage = (props, context) => ({
  advisor: `/api/admin/truevail/admin/advisors/${props.params.id}`
})

export default Page(mapResourcesToPage, mapPropsToPage)
