import DateRangeToken from '@apps/dashboards/admin/tokens/daterange'
import Container from '@admin/components/container'
import Search from '@admin/components/search'
import Loader from '@admin/components/loader'
import Chart from '@admin/components/chart'
import Icon from '@admin/components/icon'
import EmailCampaignToken from './token'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import numeral from 'numeral'
import moment from 'moment'
import React from 'react'
import qs from 'qs'

class Card extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    network: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    config: PropTypes.object,
    daterange: PropTypes.object,
    data: PropTypes.array,
    email_campaign: PropTypes.object,
    isExpanded: PropTypes.bool,
    program: PropTypes.object,
    showChart: PropTypes.bool,
    showTable: PropTypes.bool
  }

  state = {
    email_campaign_id: null,
    data: null
  }

  _handleChoose = this._handleChoose.bind(this)

  render() {
    const { daterange, isExpanded, showChart } = this.props
    const { data } = this.state
    const keys = this._getKeys()
    if(!data) return <Loader />
    const metric = this._getMetric(data)
    return (
      <div className="maha-dashboard-card-body">
        { isExpanded && 
          <div className="maha-dashboard-card-sidebar">
            <Search { ...this._getSearch() } />
          </div>
        }
        <div className="maha-dashboard-card-detail">
          <div className="maha-dashboard-card-detail-header">
            <div className="maha-dashboard-card-detail-title">
              <T text={ this._getLabel(keys.title) } />
            </div>
            <div className="maha-dashboard-card-detail-subtitle">
              <DateRangeToken daterange={ daterange } />
            </div>
            <div className="maha-dashboard-card-detail-metric">
              <div className="maha-dashboard-card-detail-metric-inner">
                <div className="maha-dashboard-card-detail-metric-text">
                  { metric.value }
                </div>
                { metric.delta &&
                  <div className={`maha-dashboard-card-detail-metric-delta ${ metric.delta.direction }`}>
                    <Icon icon={`caret-${ metric.delta.direction }`} /> { metric.delta.percent }
                  </div>
                }
              </div>
            </div>
          </div>
          { showChart &&
            <div className="maha-dashboard-card-detail-chart">
              <Chart { ...this._getChart(keys, data) } />
            </div>
          }
          { isExpanded &&
            <div className="maha-dashboard-card-detail-table">
              <table>
                <thead>
                  <tr>
                    <th><T text="t(Date)" /></th>
                    <th><T text={ keys.title } /></th>
                  </tr>
                </thead>
                <tbody>
                  { data.map((record, index) => (
                    <tr key={`record_${index}`} onClick={ this._handleClick.bind(this, keys.amount, record) }>
                      <td>{ this._getRange(record) }</td>
                      <td>
                        <div className="maha-dashboard-card-detail-amount">{ record.current_amount }</div>
                        <div className="maha-dashboard-card-detail-percent">({ numeral(record.current_rate).format('0.00%') })</div>
                        { record.delta &&
                          <div className={`maha-dashboard-card-detail-delta ${record.delta.direction}`}>
                            <Icon icon={`caret-${ record.delta.direction }`} />
                            { numeral(record.delta.percent).format('0.00%') }
                          </div>
                        }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          }
        </div>
      </div>
    )
  }

  componentDidMount() {
    this._handleFetch()
  }

  componentDidUpdate(prevProps, prevState) {
    const { email_campaign_id } = this.state
    if(email_campaign_id !== prevState.email_campaign_id) {
      this._handleFetch()
    }
  }

  _getChart(keys, data) {
    const { config, isExpanded } = this.props
    return {
      type: config.type,
      datasets: [
        {
          color: config.color,
          label: keys.title,
          points: data.map(record => ({
            x: this._getX(record),
            y: numeral(record.current_rate * 100).format('0.00')
          }))
        }
      ],
      options: {
        aspectRatio: isExpanded ? 2 : 1.5,
        points: config.points,
        tension: config.tension,
        grid: isExpanded
      }
    }
  }

  _getKeys() {
    const { metric } = this.props.config
    return {
      ...metric === 'bounce_rate' ? {
        title: 't(Bounce Rate)',
        amount: 'bounced',
        rate: 'bounce_rate'
      } : metric === 'click_rate' ? {
        title: 't(Click Rate)',
        amount: 'clicked',
        rate: 'click_rate'
      } : metric === 'complain_rate' ? {
        title: 't(Complain Rate)',
        amount: 'complained',
        rate: 'complain_rate'
      } : metric === 'open_rate' ? {
        title: 't(Open Rate)',
        amount: 'opened',
        rate: 'open_rate'
      } : metric === 'unsubscribe_rate' ? {
        title: 't(Unsubscribe Rate)',
        amount: 'unsubscribed',
        rate: 'unsubscribe_rate'
      } : metric === 'delivery_rate' ? {
        title: 't(Delivery Rate)',
        amount: 'delivered',
        rate: 'delivery_rate'
      } : {}
    }
  }

  _getLabel(label) {
    const { config, email_campaign, program } = this.props
    const { team } = this.context.admin
    if(config.title) return config.title
    if(config.scope === 'program' && team.has_programs) return `${label} - ${program.title}`
    if(config.scope === 'email_campaign') return `${label} - ${email_campaign.title}`
    return label
  }

  _getMetric(data) {
    const previous_data = data.filter(record => record.previous_rate > 0)
    const current_data = data.filter(record => record.current_rate > 0)
    const previous = previous_data.reduce((total, record) => total + record.previous_rate, 0.00) / previous_data.length
    const current = current_data.reduce((total, record) => total + record.current_rate, 0.00) / current_data.length
    const amount = current - previous
    const percent = amount === 0 ? null : previous === 0 ? 1 : Math.abs((current - previous) / previous)
    return {
      value: numeral(current).format('0.00%'),
      delta: percent > 0 ? {
        direction: amount < 0 ? 'down' : 'up',
        percent: percent && percent !== 0 ? numeral(percent).format('0.00%') : null
      } : null
    }
  }

  _getRange(record) {
    const { step } = this.props.daterange
    const { end, start } = record
    if(step === 'day') return moment(start).format('MMM D, YYYY')
    if(step === 'week') return `${ moment(start).format('MMM D') } - ${ moment(end).format('MMM D, YYYY') }`
    if(step === 'month') return moment(start).format('MMM YYYY')
    if(step === 'quarter') return `${ moment(start).format('[Q]Q YYYY') } (${ moment(start).format('MMM D') } - ${ moment(end).format('MMM D') })`
    if(step === 'year') return moment(start).format('YYYY')
  }
  
  _getSearch() {
    const { config, daterange } = this.props
    return {
      endpoint: '/api/admin/dashboards/campaigns/email_campaign_metric/campaigns',
      valueKey: 'id',
      textKey: 'title',
      deselectable: true,
      defaultQuery: {
        metric: config.metric,
        program_id: config.program_id,
        daterange 
      },
      entity: 't(email campaign)',
      format: (email_campaign) => (
        <EmailCampaignToken email_campaign={ email_campaign }/>
      ),
      onChange: this._handleChoose
    }
  }

  _getX(record) {
    const { step } = this.props.daterange
    const { end, start } = record
    if(step === 'day') return moment(start).format('M/D/YY')
    if(step === 'week') return `${ moment(start).format('M/D/YY') } - ${ moment(end).format('M/D/YY') }`
    if(step === 'month') return moment(start).format('MMM YYYY')
    if(step === 'quarter') return moment(start).format('[Q]Q YYYY')
    if(step === 'year') return moment(start).format('YYYY')
  }

  _handleChoose(email_campaign_id) {
    this.setState({ 
      email_campaign_id: email_campaign_id || null
    })
  }

  _handleClick(metric, record) {
    const { email_campaign, program } = this.props
    const filter = qs.stringify({
      metric,
      program_id: program.id,
      ...email_campaign ? { email_campaign_id: email_campaign.id } : {},
      start_date: moment(record.start).format('YYYY-MM-DD'),
      end_date: moment(record.end).format('YYYY-MM-DD')
    }, { encode: false })
    this.context.router.push(`/admin/dashboards/campaigns/email_campaign_metric?${filter}`)
  }

  _handleFetch() {
    const { config, daterange } = this.props
    const { email_campaign_id } = this.state
    this.context.network.request({
      endpoint: '/api/admin/dashboards/campaigns/email_campaign_metric/card',
      method: 'GET',
      filter: {
        metric: config.metric,
        email_campaign_id,
        program_id: config.program_id,
        daterange 
      },
      onSuccess: ({ data }) => {
        this.setState({ data })
      }
    })
  }

}

const mapResources = (props, context) => ({
  ...props.config.program_id ? {
    program: `/api/admin/team/programs/${props.config.program_id}`
  } : {}
})

export default Container(mapResources)(Card)
