import EmailCampaignToken from '@apps/truevail/admin/tokens/email_campaign'
import Panel from '@admin/components/panel'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Email Campaigns)',
  collection: {
    endpoint: '/api/admin/truevail/agency/email_performances',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Email Campaign)', key: 'title', primary: true, format: (email_campaign) => (
        <EmailCampaignToken email_campaign={ email_campaign } show_provider={ false } show_tags={ true } show_voice={ true } />
      ) },
      { label: 't(Sent)', key: 'sent', collapsing: true, align: 'right' },
      { label: 't(Delivery)', key: 'delivery_rate', collapsing: true, align: 'right', format: 'rate' },
      { label: 't(Open)', key: 'open_rate', collapsing: true, align: 'right', format: 'rate' },
      { label: 't(Click)', key: 'click_rate', collapsing: true, align: 'right', format: 'rate' },
      { label: 't(Bounce)', key: 'bounce_rate', collapsing: true, align: 'right', format: 'rate'},
      { label: 't(Complain)', key: 'complain_rate', collapsing: true, align: 'right', format: 'rate' },
      { label: 't(Unsubscribe)', key: 'unsubscribe_rate', collapsing: true, align: 'right', format: 'rate' }
    ],
    defaultSort: { key: 'created_at', order: 'desc' },
    empty: {
      icon: 'envelope',
      title: 't(No Email Campaigns)',
      text: 't(Your advisors have not yet sent any emails)'
    },
    entity: 'email campaign',
    onClick: (record) => context.router.push(`/admin/truevail/agency/email_performance/${record.id}`)
  }
})

export default Panel(null, mapPropsToPanel)
