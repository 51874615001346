import Page from '@admin/components/page'
import Campaigns from './campaigns'
import Overview from './overview'
import React from 'react'

const mapPropsToPage = (props, context, resources, page) => ({
  title: resources.email_campaign.title,
  manager: {
    path: `/admin/truevail/agency/email_performance/${resources.email_campaign.id}`,
    items: [
      {
        label: 't(Overview)',
        panel: <Overview email_campaign={ resources.email_campaign } />
      },
      {
        label: 't(Campaigns)',
        path: '/design',
        panel: <Campaigns email_campaign={ resources.email_campaign }  />
      }
    ]
  }
})

const mapResourcesToPage = (props, context) => ({
  email_campaign: `/api/admin/truevail/agency/email_performances/${props.params.id}`
})

export default Page(mapResourcesToPage, mapPropsToPage)
