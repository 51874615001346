import SocialCampaignVariantToken from './social_campaign_variant'
import Page from '@admin/components/page'
import React from 'react'

const mapPropsToPage = (props, context) => ({
  title: 't(Posts)',
  access: { 
    rights: { 
      $and: [
        { $allOf: ['dashboards:access_app'] },
        { $oneOf: ['campaigns:manage_social_campaigns','campaigns:view_social_campaigns'] }
      ]
    } 
  },
  collection: {
    endpoint: '/api/admin/dashboards/truevail/aggregate_social_campaign_metric/report',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },  
      { label: 't(Social Camapign)', key: 'social_campaign.title', sort: 'social_campaign', format: (social_campaign_variant) => (
        <SocialCampaignVariantToken social_campaign_variant={ social_campaign_variant } />
      )  },
      ...props.query.metric === 'comments' ? [
        { label: 't(Comments)', key: 'metric', collapsing: true, align: 'right' }
      ] : props.query.metric === 'clicks' ? [
        { label: 't(Clicks)', key: 'metric', collapsing: true, align: 'right' }
      ] : props.query.metric === 'engagement' ? [
        { label: 't(Engagement)', key: 'metric', collapsing: true, align: 'right' }
      ] : props.query.metric === 'impressions' ? [
        { label: 't(Impressions)', key: 'metric', collapsing: true, align: 'right' }
      ] : props.query.metric === 'reach' ? [
        { label: 't(Reach)', key: 'metric', collapsing: true, align: 'right' }
      ] : props.query.metric === 'shares' ? [
        { label: 't(Shares)', key: 'metric', collapsing: true, align: 'right' }
      ] : props.query.metric === 'saves' ? [
        { label: 't(Saves)', key: 'metric', collapsing: true, align: 'right' }
      ] : props.query.metric === 'video_views' ? [
        { label: 't(Video Views)', key: 'metric', collapsing: true, align: 'right' }
      ] : props.query.metric === 'reactions' ? [
        { label: 't(Reactions)', key: 'metric', collapsing: true, align: 'right' }
      ] : []
    ],
    defaultSort: { key: 'metric', order: 'desc' },
    empty: {
      icon: 'comments',
      title: 't(No Social Campaigns)',
      text: 't(There are no social campaigns for this time period)'
    },
    entity: 'social campaign',
    onClick: (record) => context.router.push(`/campaigns/social/${record.social_campaign.id}`)
  }
})

export default Page(null, mapPropsToPage)
