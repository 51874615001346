import VoiceToken from '@apps/truevail/admin/tokens/voice'
import MJSONPreview from '@admin/components/mjson_preview'
import TagsToken from '@apps/crm/admin/tokens/tags'
import Carousel from '@admin/components/carousel'
import Details from '@admin/components/details'
import Button from '@admin/components/button'
import Panel from '@admin/components/panel'
import PropTypes from 'prop-types'
import React from 'react'

const Overview = ({ social_campaign }) => {

  const design_url = social_campaign.design_url ? {
    label: social_campaign.design_url,
    className: 'link',
    link: social_campaign.design_url
  } : null

  const slides = social_campaign.variants.map((variant, index) => (
    <MJSONPreview entity={ variant } table="truevail_social_campaign_variants" key={`variant_${index}`} />
  ))

  const details = {
    header: <Carousel slides={ slides } />,
    sections: [
      {
        title: 't(Social Campaign Details)',
        items: [
          { label: 't(Title)', content: social_campaign.title },
          { label: 't(Type)', content: social_campaign.type.toUpperCase() },
          { label: 't(Voice)', content: <VoiceToken perspective={ social_campaign.perspective } language={ social_campaign.language } /> },
          { label: 't(Design URL)', content: design_url ? <Button { ...design_url } /> : null },
          { label: 't(Tags)', content: social_campaign.tags.length > 0 ? <TagsToken tags={ social_campaign.tags } /> : null }
        ]
      }
    ]
  }

  return <Details { ...details } />

}

Overview.propTypes = {
  social_campaign: PropTypes.object
}

const mapPropsToPanel = (props, context) => ({
  title: 't(Overview)',
  panel: <Overview social_campaign={ props.social_campaign } />
})

export default Panel(null, mapPropsToPanel)
