import SocialCampaign from './social_campaign'
import Panel from '@admin/components/panel'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Social Campaigns)',
  collection: {
    endpoint: `/api/admin/truevail/agency/social_performances/${props.social_campaign.id}/campaigns`,
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Advisor)', key: 'advisor.title', sort: 'advisor', format: (social_campaign) => (
        <SocialCampaign social_campaign={ social_campaign } />
      ) },
      { label: 't(Impressions)', key: 'impressions', collapsing: true, align: 'right' },
      { label: 't(Engagement)', key: 'engagement', collapsing: true, align: 'right' },
      { label: 't(Reach)', key: 'reach', collapsing: true, align: 'right' },
      { label: 't(Video Views)', key: 'video_views', collapsing: true, align: 'right' },
      { label: 't(Reactions)', key: 'reactions', collapsing: true, align: 'right'},
      { label: 't(Comments)', key: 'comments', collapsing: true, align: 'right' },
      { label: 't(Shares)', key: 'shares', collapsing: true, align: 'right' },
      { label: 't(Saves)', key: 'saves', collapsing: true, align: 'right' }
    ],
    defaultSort: { key: 'advisor', order: 'asc' },
    empty: {
      icon: 'envelope',
      title: 't(No Social Campaigns)',
      text: 't(Your advisors have not yet sent any socials)'
    },
    entity: 'publishing',
    onClick: (record) => {
      const { provider, host } = context
      host.window.open(`${provider.admin_host}/mjson/dynamic/campaigns_social_campaigns/${record.advisor_social_campaign.code}`)
    }
  }
})

export default Panel(null, mapPropsToPanel)
