import Import from '@core/lib/admin/components/import'
import Configure from './configure'
import Complete from './complete'
import PropTypes from 'prop-types'
import React from 'react'

class ContactImport extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    modal: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    program_id: PropTypes.number,
    onBack: PropTypes.func,
    onCancel: PropTypes.func
  }

  render() {
    return <Import { ...this._getImport() } />
  }

  _getImport() {
    const { program_id, onBack, onCancel } = this.props
    return {
      sources: ['file','mailchimp','constantcontact','googlecontacts','outlook'],
      configure: Configure,
      complete: Complete,
      defaults: {
        program_id
      },
      entity: 'contact',
      object_type: 'crm_contacts',
      summary: this._getSummary,
      onBack,
      onCancel
    }
  }

  _getSummary(_import) {
    const { channels, lists, tags } = _import.config
    return [
      {
        icon: 'list',
        color: 'teal',
        ...lists && lists.length > 0 ? {
          label: 'Contacts will be subscribed to the following lists',
          items: lists.map(list => list.title)  
        } : {
          label: 'Contacts will be not be subscribed to any lists'
        } 
      },
      {
        icon: 'handshake-o', 
        color: 'teal',
        ...tags && tags.length > 0 ? {
          label: 'Contacts will be tagged with the following tags',
          items: tags
        } : {
          label: 'Contacts will be not be tagged with any tags'
        } 
      },
      {
        icon: 'list', 
        color: 'teal',
        ...channels && channels.length > 0 ? {
          label: 'Contacts will be opted in to the following channels',
          items: channels
        } : {
          label: 'Contacts will not be opted in to any channels'
        } 
      }
    ]
  }

}

export default ContactImport
