import MJSONScreenshot from '@admin/components/mjson_screenshot'
import VoiceToken from '@apps/truevail/admin/tokens/voice'
import Format from '@admin/components/format'
import PropTypes from 'prop-types'
import React from 'react'

const getScreenshot = (entity) => ({
  engine: 'mjson',
  entity: `campaigns_email_campaigns/${entity.code}`,
  width: 50,
  height: 38,
  screenshoted_at: entity.screenshoted_at
})

const EmailCampaignToken = ({ email_campaign }) => (
  <div className="campaign-token compact">
    <div className="campaign-token-preview">
      <div className="campaign-token-preview-inner">
        <MJSONScreenshot { ...getScreenshot(email_campaign) } />
      </div>
    </div>
    <div className="campaign-token-label">
      <div className="token-link">
        { email_campaign.title }
      </div>
      <div className="token-stats">
        <VoiceToken perspective={ email_campaign.perspective } language={ email_campaign.language } />
      </div>
    </div>
    <div className="campaign-token-metric">
      { <Format value={email_campaign.metric} format="rate" /> }
    </div>
  </div>
)

EmailCampaignToken.propTypes = {
  email_campaign: PropTypes.object
}

export default EmailCampaignToken
