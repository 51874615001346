import NumberField from '@admin/components/form/numberfield'
import Dropdown from '@admin/components/form/dropdown'
import PropTypes from 'prop-types'
import React from 'react'

class DurationField extends React.PureComponent {

  static propTypes = {
    defaultValue: PropTypes.number,
    value: PropTypes.number,
    onChange: PropTypes.func,
    onReady: PropTypes.func
  }

  state = {}

  render() {
    if(this.state.value === undefined) return null
    return (
      <div className="durationfield">
        <NumberField { ...this._getValue() } />
        <Dropdown { ...this._getUnits() } />
      </div>
    )
  }

  componentDidMount() {
    const defaultValue = this._getDefaultValue()
    this._handleSet(defaultValue)
    this.props.onReady()
  }

  componentDidUpdate(prevProps, prevState) {
    const { units, value } = this.state
    if(units !== prevState.units) {
      this._handleChange()
    }
    if(value !== prevState.value) {
      this._handleChange()
    }
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : 0
  }

  _getUnits() {
    const { units } = this.state
    return {
      entity: 'unit',
      options: ['hours','days','weeks'],
      defaultValue: units,
      onChange: this._handleUpdate.bind(this, 'units')
    }
  }

  _getValue() {
    const { value } = this.state
    return {
      defaultValue: value,
      onChange: this._handleUpdate.bind(this, 'value')
    }
  }

  _getNormalized() {
    const { value, units } = this.state
    if(units === 'weeks') return value * 7 * 24
    if(units === 'days') return value * 24
    return value
  }

  _handleChange() {
    const value = this._getNormalized()
    this.props.onChange(value)
  }

  _handleSet(hours) {
    this.setState({
      value: hours % 168 === 0 ? hours / 168 : hours % 24 === 0 ? hours / 24 : hours,
      units: hours % 168 === 0 ? 'weeks' : hours % 24 === 0 ? 'days' : 'hours'
    })
  }

  _handleUpdate(key, value) {
    this.setState({ [key]: value })
  }

}

export default DurationField
