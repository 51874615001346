import EmailCampaigns from './email_campaigns'
import Page from '@admin/components/page'
import Participants from './participants'
import Overview from './overview'
import React from 'react'

const mapPropsToPage = (props, context, resources, page) => ({
  title: resources.webinar.title,
  manager: {
    path: `/admin/truevail/admin/webinars/${resources.webinar.id}`,
    items: [
      {
        label: 't(Overview)',
        panel: <Overview webinar={ resources.webinar } />
      },
      {
        label: 't(Campaigns)',
        access: { rights: { $oneOf: ['campaigns:access_app'] } },
        children: [
          {
            label: 't(Email)',
            access: { 
              $and: [
                { rights: { $allOf: ['campaigns:access_app'] } },
                { rights: { $oneOf: ['campaigns:view_email_campaigns','campaigns:manage_email_campaigns'] } }
              ] 
            },
            path: '/email_campaigns',
            panel: <EmailCampaigns webinar={ resources.webinar } />
          }
        ]
      },
      {
        label: 't(Participants)',
        path: '/participants',
        panel: <Participants webinar={ resources.webinar } />
      }
    ]
  }
})

const mapResourcesToPage = (props, context) => ({
  webinar: `/api/admin/truevail/admin/webinars/${props.params.id}`
})

export default Page(mapResourcesToPage, mapPropsToPage)
