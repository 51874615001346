import StripeCardField from '@apps/finance/admin/components/paymentfield/stripe/cardfield'
import CardToken from '@apps/finance/admin/tokens/card'
import Container from '@admin/components/container'
import Date from '@admin/components/date'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import moment from 'moment'
import React from 'react'

class ChangePlan extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    modal: PropTypes.object
  }

  static propTypes = {
    plans: PropTypes.array,
    service: PropTypes.object,
    subscription: PropTypes.object
  }

  state = {
    config: {}
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form {...this._getForm()} />
  }

  _getForm() {
    const { plans, service, subscription } = this.props
    const { programs } = this.context.admin
    const { config } = this.state
    return {
      title: 't(Change Plan)',
      method: 'PATCH',
      action: `/api/admin/subscriptions/services/${service.id}/subscriptions/${subscription.id}/change`,
      saeText: 't(Change)',
      onCancel: this._handleCancel,
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: subscription.current_period.type === 'trial' ? [
            { 
              label: 't(Plan Change)', 
              type: 'segment', 
              fields: [
                { 
                  label: 't(Current Plan)',
                  name: 'current_plan_id', 
                  type: 'textfield', 
                  disabled: true,
                  value: subscription.current_period.plan.title
                },
                { 
                  label: 't(New Plan)',
                  name: 'plan_id', 
                  type: 'dropdown', 
                  options: plans.filter(plan => {
                    return plan.id !== subscription.current_period.plan.id
                  }).map(plan => ({
                    value: plan.id,
                    text: plan.title
                  })),
                  selectFirst: true,
                  required: true
                }    
              ]
            }            
          ] : [
            { 
              label: 't(Plan Change)', 
              type: 'segment', 
              fields: [
                { 
                  label: 't(Current Plan)',
                  name: 'current_plan_id', 
                  type: 'textfield', 
                  disabled: true,
                  show: subscription.next_period.id === subscription.current_period.id,
                  value: subscription.current_period.plan.title
                },
                { 
                  label: 't(Upcoming Plan)',
                  name: 'next_plan_id', 
                  type: 'textfield', 
                  disabled: true,
                  show: subscription.next_period.id !== subscription.current_period.id,
                  value: subscription.next_period.plan.title
                },
                { 
                  label: 't(New Plan)',
                  name: 'plan_id', 
                  type: 'dropdown', 
                  options: plans.filter(plan => {
                    return plan.id !== subscription.next_period.plan.id
                  }).map(plan => ({
                    value: plan.id,
                    text: plan.title
                  })),
                  selectFirst: true,
                  required: true
                }    
              ],
              after: (
                <span className="alert">This change will occur at the beginning of the next billing cycle on <Date date={ moment(subscription.current_cycle.end_date).add(1,'day') } /></span>
              )
            },
            { 
              label: 't(Payment Method)', 
              type: 'segment', 
              fields: [
                { 
                  name: 'payment_strategy', 
                  type: 'hidden', 
                  value: 'card' 
                },
                { 
                  name: 'card_strategy', 
                  type: 'radiogroup', 
                  options: [
                    { value: 'existing', text: 't(Use existing card)' },
                    { value: 'previous', text: 't(Use a previous card)' },
                    { value: 'new', text: 't(Use a new card)' }
                  ], 
                  defaultValue: 'existing' 
                },
                { 
                  name: 'payment_method_id', 
                  type: 'dropdown', 
                  show: config.card_strategy === 'previous', 
                  endpoint: '/api/admin/finance/payment_methods', 
                  filter: { contact_id: { $eq: subscription.contact.id } }, 
                  valueKey: 'id', 
                  textKey: 'description', format: (payment_method) => (
                    <CardToken payment_method={ payment_method } />
                  ), 
                  required: true, 
                  selectFirst: true 
                },
                { 
                  name: 'payment', 
                  type: StripeCardField, 
                  show: config.card_strategy === 'new', 
                  stripeAccount: programs[0].stripe_id 
                }
              ] 
            },
            {
              label: 't(Accept Payment)',
              name: 'payment_timing',
              type: 'radiogroup', 
              deselectable: false, 
              options: [
                { value: 'renewal', text: 't(When the plan renews)' },
                { value: 'now', text: 't(Immediately)' }
              ], 
              defaultValue: 'renewal' 
            }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSuccess() {
    this.context.modal.close()
  }

}

const mapResources = (props, context) => ({
  plans: `/api/admin/subscriptions/services/${props.service.id}/plans`
})

export default Container(mapResources)(ChangePlan)
