import StatusToken from '@admin/tokens/status'
import Panel from '@admin/components/panel'

const mapPropsToPanel = (props, context) => ({
  title: 't(Webinars)',
  collection: {
    endpoint: '/api/admin/truevail/admin/webinars',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Title)', key: 'title', primary:  true },
      { label: 't(Start)', key: 'started_at', format: 'datetime' },
      { label: 't(Registered)', key: 'participant_count', collapsing: true, align: 'right' },
      { label: 't(Attended)', key: 'attended_count', collapsing: true, align: 'right' },
      { label: 't(Converted)', key: 'converted_count', collapsing: true, align: 'right' },
      { label: 't(Status)', key: 'status', collapsing: true, primary: true, format: StatusToken }
    ],
    defaultSort: { key: 'started_at', order: 'desc' },
    empty: {
      title: 't(No Webinars)',
      text: 't(There are no scheduled webinars)',
      icon: 'laptop'
    },
    entity: 'webinar',
    onClick: (record) => context.router.push(`/admin/truevail/admin/webinars/${record.id}`)
  }
})

export default Panel(null, mapPropsToPanel)
