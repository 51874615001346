import { createBrowserHistory } from 'history'
import PropTypes from 'prop-types'
import React from 'react'
import _ from 'lodash'

const apps = ['analytics','automation','calendar','campaigns','crm','dashboards','datasets','domains','drive','email','events','finance','forms','phone','platform','sales','sites','stores','subscriptions','support','tasks','team','truevail','websites']
const pages = ['assets','attachments','emails','threads','help','notifications','search']

export const RouterContext = React.createContext()
RouterContext.displayName = 'RouterContext'

export const useRouterContext = () => React.useContext(RouterContext)

class Router extends React.Component {

  static childContextTypes = {
    router: PropTypes.object
  }

  static propTypes = {
    context: PropTypes.shape({
      analytics: PropTypes.object
    }),
    action: PropTypes.string,
    apps: PropTypes.array,
    children: PropTypes.any,
    history: PropTypes.array,
    last: PropTypes.array,
    team: PropTypes.object,
    teams: PropTypes.array,
    onForward: PropTypes.func,
    onPop: PropTypes.func,
    onPush: PropTypes.func,
    onReplace: PropTypes.func,
    onReset: PropTypes.func,
    onChooseTeam: PropTypes.func
  }

  _handleGoBack = this._handleGoBack.bind(this)
  _handleListen = this._handleListen.bind(this)
  _handlePush = this._handlePush.bind(this)
  _handleReplace = this._handleReplace.bind(this)
  _handleReset = this._handleReset.bind(this)

  historyRef = null
  listenerRef = null

  render() {
    const { action, children } = this.props
    return (
      <RouterContext.Provider value={ this.getChildContext() }>
        { action ? React.Children.map(children, child => {
          return React.cloneElement(child, this.getChildContext())
        }) : null }
      </RouterContext.Provider>
    )
  }

  componentDidMount() {
    this.historyRef = createBrowserHistory()
    this.listenerRef = this.historyRef.listen(this._handleListen)
    this.props.onPush({
      pathname: window.location.pathname,
      hash: window.location.hash.slice(1),
      search: window.location.search.slice(1)
    })
  }

  componentWillUnmount() {
    this.listenerRef()
  }

  getChildContext() {
    const { action, history } = this.props
    return {
      router: {
        action,
        history,
        location: this.historyRef ? this.historyRef.location : null,
        goBack: this._handleGoBack,
        push: this._handlePush,
        replace: this._handleReplace,
        reset: this._handleReset
      }
    }
  }

  _getAction({ action, location }) {
    const { history, last } = this.props
    if(action === 'POP' && last[last.length - 1]?.key === location.key) return 'FORWARD'
    if(action === 'POP' && /^\/(activate|reset|signin)/.test(history[history.length - 1].pathname)) return 'NOOP'
    if(action === 'POP' && history[history.length - 2]?.key !== location.key) return 'PUSH'
    return action
  }

  _getPathname(pathname) {
    const { teams, team } = this.props
    const localpath = pathname.replace(/^\/admin/, '')
    if(/^\/(activate|reset|signin)/.test(localpath)) return localpath
    if(_.includes(['','/'], localpath)) return team ? `/${team.subdomain}` : '/'
    const parts = pathname.replace(/^\/|\/$/g, '').split('/')
    if(parts[0] === 'admin') return `/${team.subdomain}${localpath}`
    const targetTeam = teams.find(item => item.subdomain === parts[0])
    const testApp = apps.find(item => item === parts[0])
    const targetApp = apps.find(item => item === parts[1])
    const testPage = pages.find(item => item === parts[0])
    const targetPage = pages.find(item => item === parts[1])
    if(!targetTeam && !testApp && !testPage) return '/notfound'
    if(!targetTeam && (targetApp || targetPage || testPage)) return `/${team.subdomain}${localpath}`
    if((!testApp || targetPage || targetApp) && targetTeam.subdomain !== team.subdomain) {
      const redirect = parts.length > 1 ? `/${targetTeam.subdomain}/${parts.slice(1).join('/')}` : null
      return this.props.onChooseTeam(targetTeam.id, redirect)
    }
    if(targetTeam && targetPage) return `/${team.subdomain}/${targetPage}`
    if(targetTeam && targetApp) return localpath
    if(localpath === `/${team.subdomain}`) return localpath
    if(testApp) return `/${team.subdomain}${localpath}`
    const fullpath = parts.length > 1 ? `/${parts.slice(1).join('/')}` : ''
    return `/${team.subdomain}${fullpath}`
  }

  _getRoute(path) {
    if(!_.isString(path)) return path
    const route = new URL(`http://localhost${path}`)
    return {
      pathname: route.pathname,
      search: route.search,
      hash: route.hash
    }
  }

  _handleGoBack() {
    this.props.context.analytics.track('broswed back')
    this.historyRef.back()
  }

  _handleListen(route) {
    const { history } = this.props
    const { location } = route
    const action = this._getAction(route)
    if(action === 'FORWARD') {
      this.props.onForward(location)
      this._getPathname(location.pathname)
    } else if(action === 'PUSH') {
      this.props.onPush(this._getRoute(location))
    } else if(action === 'REPLACE') {
      this.props.onReplace(this._getRoute(location))
    } else if(action === 'POP') {
      this.props.onPop()
      this._getPathname(location.pathname)
    } else if(action === 'NOOP') {
      this.historyRef.replace(history.slice(-1)[0])
    }
  }

  _handleReplace(path) {
    const route = this._getRoute(path)
    route.pathname = this._getPathname(route.pathname)
    if(route) this.historyRef.replace(route)
  }

  _handleReset(path) {
    const route = this._getRoute(path)
    this.props.onReset(route)
  }

  _handlePush(path) {
    const { history } = this.props
    const route = this._getRoute(path)
    route.pathname = this._getPathname(route.pathname)
    if(!route.pathname) return
    if(route.pathname === history[history.length - 1].pathname) return
    this.props.context.analytics.track(`browsed to '${route.pathname}'`)
    this.historyRef.push(route)
  }

}

export default Router
