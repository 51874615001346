import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Convert extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    modal: PropTypes.object
  }

  static propTypes = {
    demo: PropTypes.object
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { team } = this.context.admin
    const { demo } = this.props
    return {
      title: 't(Convert Demo)',
      action: `/api/admin/truevail/admin/demos/${demo.id}/convert`,
      method: 'PATCH',
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Signup)', ame: 'signup_id', type: 'lookup', endpoint: '/api/admin/truevail/admin/signups', valueKey: 'id', textKey: 'name', filter: { demo: { $nl: true } }, required: true }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess(agency) {
    this.context.modal.close()
  }

}

export default Convert
