import DateRangeToken from '@apps/dashboards/admin/tokens/daterange'
import Container from '@admin/components/container'
import Chart from '@admin/components/chart'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import numeral from 'numeral'
import moment from 'moment'
import React from 'react'
import qs from 'qs'

class Card extends React.Component {

  static contextTypes = {
    router: PropTypes.object
  }

  static propTypes = {
    config: PropTypes.object,
    daterange: PropTypes.object,
    data: PropTypes.array,
    form: PropTypes.object,
    isExpanded: PropTypes.bool,
    program: PropTypes.object,
    showChart: PropTypes.bool,
    showTable: PropTypes.bool
  }

  render() {
    const { data, daterange, showChart, showTable } = this.props
    return (
      <div className="maha-dashboard-card-detail">
        <div className="maha-dashboard-card-detail-header">
          <div className="maha-dashboard-card-detail-title">
            <T text={ this._getLabel('t(Form Responses)') } />
          </div>
          <div className="maha-dashboard-card-detail-subtitle">
            <DateRangeToken daterange={ daterange } />
          </div>
          <div className="maha-dashboard-card-detail-metric">
            <div className="maha-dashboard-card-detail-metric-inner">
              <div className="maha-dashboard-card-detail-metric-text">
                { this._getMetric() }
              </div>
            </div>
          </div>
        </div>
        { showChart &&
          <div className="maha-dashboard-card-detail-chart">
            <Chart { ...this._getChart() } />
          </div>
        }
        { showTable &&
          <div className="maha-dashboard-card-detail-table">
            <table>
              <thead>
                <tr>
                  <th><T text="t(Date)" /></th>
                  <th><T text="t(Responses)" /></th>
                </tr>
              </thead>
              <tbody>
                { data.map((record, index) => (
                  <tr key={`record_${index}`} onClick={ this._handleClick.bind(this, record) }>
                    <td>{ this._getRange(record) }</td>
                    <td>{ record.total }</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        }
      </div>
    )
  }

  _getX(record) {
    const { step } = this.props.daterange
    const { end, start } = record
    if(step === 'day') return moment(start).format('M/D/YY')
    if(step === 'week') return `${ moment(start).format('M/D/YY') } - ${ moment(end).format('M/D/YY') }`
    if(step === 'month') return moment(start).format('MMM YYYY')
    if(step === 'quarter') return moment(start).format('[Q]Q YYYY')
    if(step === 'year') return moment(start).format('YYYY')
  }

  _getRange(record) {
    const { step } = this.props.daterange
    const { end, start } = record
    if(step === 'day') return moment(start).format('MMM D, YYYY')
    if(step === 'week') return `${ moment(start).format('MMM D') } - ${ moment(end).format('MMM D, YYYY') }`
    if(step === 'month') return moment(start).format('MMM YYYY')
    if(step === 'quarter') return `${ moment(start).format('[Q]Q YYYY') } (${ moment(start).format('MMM D') } - ${ moment(end).format('MMM D') })`
    if(step === 'year') return moment(start).format('YYYY')
  }

  _getChart() {
    const { config, data, isExpanded } = this.props
    return {
      type: config.type,
      datasets: [
        {
          color: config.color,
          label: 't(Responses)',
          points: data.map(record => ({
            x: this._getX(record),
            y: record.total
          }))
        }
      ],
      options: {
        aspectRatio: isExpanded ? 2 : 1.5,
        legend: isExpanded,
        points: config.points,
        tension: config.tension,
        grid: isExpanded
      }
    }
  }

  _getLabel(label) {
    const { config, form, program } = this.props
    const { team } = this.context.admin
    if(config.title) return config.title
    if(config.scope === 'program' && team.has_programs) return `${label} - ${program.title}`
    if(config.scope === 'form') return `${label} - ${form.title}`
    return label
  }

  _getMetric() {
    const { data } = this.props
    const metric = data.reduce((total, record) => {
      return total + record.total
    }, 0)
    return metric < 1000 ? metric : numeral(metric).format('0.00a').toUpperCase()
  }

  _handleClick(record) {
    const { form, program } = this.props
    const filter = qs.stringify({
      program_id: program.id,
      ...form ? { form_id: form.id } : {},
      start_date: moment(record.start).format('YYYY-MM-DD'),
      end_date: moment(record.end).format('YYYY-MM-DD')
    }, { encode: false })
    this.context.router.push(`/admin/dashboards/forms/responses?${filter}`)
  }

}

const mapResources = (props, context) => ({
  program: `/api/admin/team/programs/${props.config.program_id}`,
  ...props.config.scope === 'form' ? {
    form: `/api/admin/forms/forms/${props.config.form_id}`
  } : {},
  data: {
    endpoint: '/api/admin/dashboards/forms/responses/card',
    filter: {
      program_id: props.config.program_id,
      ...props.config.scope === 'form' ? {
        form_id: props.config.form_id
      } : {},
      daterange: props.daterange 
    }
  }
})

export default Container(mapResources)(Card)
