import PaymentMethodToken from '@apps/finance/admin/tokens/payment_method'
import CreditToken from '@apps/finance/admin/tokens/credit'
import Container from '@admin/components/container'
import BraintreeCardField from './braintree/cardfield'
import BraintreeACHField from './braintree/achfield'
import StripeCardField from './stripe/cardfield'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class PaymentField extends React.Component {

  static contextTypes = {
    admin: PropTypes.object
  }

  static propTypes = {
    amount: PropTypes.number,
    banks: PropTypes.array,
    cards: PropTypes.array,
    contact: PropTypes.object,
    defaultValue: PropTypes.object,
    allowedMethods: PropTypes.array,
    program: PropTypes.object,
    token: PropTypes.string,
    value: PropTypes.object,
    onChange: PropTypes.func,
    onReady: PropTypes.func,
    onValid: PropTypes.func
  }

  static defaultProps = {
    allowedMethods: ['card','ach','check','ebt','cash','credit','scholarship']
  }

  formRef = React.createRef()

  state = {
    config: {},
    ready: false
  }

  _handleFailure = this._handleFailure.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)
  _handleUpdate = this._handleUpdate.bind(this)
  _handleValidate = this._handleValidate.bind(this)

  render() {
    return (
      <div className="finance-paymentfield">
        <Form {...this._getForm()} />
      </div>
    )
  }

  componentDidMount() {
    this.props.onReady(this._handleValidate)
  }

  _getForm() {
    const methods = this._getMethods()
    return {
      ref: this.formRef,
      inline: true,
      onChange: this._handleUpdate,
      onSuccess: this._handleSuccess,
      onFailue: this._handleFailure,
      sections: [
        {
          fields: methods.length > 1 ? [
            { 
              type: 'segment', 
              fields: [
                { 
                  name: 'method', 
                  type: 'radiogroup', 
                  deselectable: false, 
                  placeholder: 't(Choose payment method)', 
                  options: methods, 
                  valueKey: 'value', 
                  textKey: 'text', 
                  format: PaymentMethodToken, 
                  required: true, 
                  defaultValue: methods[0] 
                },
                ..._.includes(methods, 'card') ? this._getCard() : [],
                ..._.includes(methods, 'ach') ? this._getAch() : [],
                ..._.includes(methods, 'check') ? this._getCheck() : [],
                ..._.includes(methods, 'ebt') ? this._getEBT() : [],
                ..._.includes(methods, 'credit') ? this._getCredit() : [],
                ..._.includes(methods, 'scholarship') ? this._getScholarship() : []
              ] 
            }
          ] : [
            { 
              name: 'method', 
              type: 'hidden', 
              defaultValue: methods[0] 
            },
            ..._.includes(methods, 'card') ? this._getCard() : [],
            ..._.includes(methods, 'ach') ? this._getAch() : [],
            ..._.includes(methods, 'check') ? this._getCheck() : [],
            ..._.includes(methods, 'ebt') ? this._getEBT() : [],
            ..._.includes(methods, 'credit') ? this._getCredit() : [],
            ..._.includes(methods, 'scholarship') ? this._getScholarship() : []
          ]
        }
      ]
    }
  }

  _getAch() {
    const { amount, banks, program, token } = this.props
    const { config } = this.state
    return [
      { label: 't(Bank Account)', type: 'segment', show: config.method === 'ach', fields: banks.length > 0 ? [
        { name: 'ach_strategy', type: 'radiogroup', options: [
          { value: 'previous', text: 't(Use a previous account)' },
          { value: 'new', text: 't(Use a new account)' }
        ], defaultValue: 'previous' },
        { name: 'ach_payment_method_id', type: 'dropdown', show: config.ach_strategy === 'previous', placeholder: 'Select a bank account', options: banks.map(bank => ({
          value: bank.id,
          text: bank.description
        })), selectFirst: true },
        { name: 'ach_payment', type: BraintreeACHField, show: config.ach_strategy === 'new', program, token, amount }
      ] : [
        { name: 'ach_payment', type: BraintreeACHField, program, token, amount }
      ] }
    ]
  }

  _getCard() {
    const { amount, cards, program, token } = this.props
    const { team } = this.context.admin
    const { config } = this.state
    const CardField = team.payment_processor === 'stripe' ? StripeCardField : BraintreeCardField
    return [
      { label: 't(Credit Card)', type: 'segment', show: config.method === 'card', fields: cards.length > 0 ? [
        { name: 'card_strategy', type: 'radiogroup', options: [
          { value: 'previous', text: 't(Use a previous card)' },
          { value: 'new', text: 't(Use a new card)' }
        ], defaultValue: 'previous' },
        { name: 'card_payment_method_id', type: 'dropdown', show: config.card_strategy === 'previous', placeholder: 'Select a card', options: cards.map(card => ({
          value: card.id,
          text: card.description
        })), selectFirst: true },
        { name: 'card_payment', type: CardField, stripeAccount: program.stripe_id, show: config.card_strategy === 'new', color: 'blue', token, amount }
      ] : [
        { name: 'card_payment', type: CardField, stripeAccount: program.stripe_id, color: 'blue', token, amount }
      ] }
    ]
  }

  _getCheck() {
    const { config } = this.state
    return [
      { label: 't(Photo)', name: 'photo_id', type: 'attachmentfield', show: config.method === 'check', types: ['jpg','jpeg','gif','png'], prompt: 'Upload Check Photo' },
      { label: 't(Check Number)', name: 'reference', type: 'textfield', show: config.method === 'check', required: true }  
    ] 
  }

  _getCredit() {
    const { contact } = this.props
    const { config } = this.state
    return [
      { label: 't(Customer Credit)', name: 'credit_id', type: 'lookup', show: config.method === 'credit', endpoint: '/api/admin/finance/credits', filter: { contact_id: { $eq: contact.id }, balance: { $gt: 0 } }, valueKey: 'id', textKey: 'description', required: true, format: CreditToken }
    ]
  }

  _getEBT() {
    const { config } = this.state
    return [
      { label: 't(Transaction ID)', name: 'reference', type: 'textfield', show: config.method === 'ebt', required: true }
    ]
  }

  _getScholarship() {
    const { contact } = this.props
    const { config } = this.state
    return [
      { label: 't(Scholarship)', name: 'scholarship_id', type: 'lookup', show: config.method === 'scholarship', endpoint: '/api/admin/finance/scholarships', filter: { contact_id: { $eq: contact.id } }, valueKey: 'id', textKey: 'description', form: this._getScholarshipForm(), required: true }
    ]
  }

  _getMethods() {
    const { payment_processor } = this.context.admin.team
    const { allowedMethods } = this.props
    return allowedMethods.filter(method => {
      if(method === 'ach' && payment_processor !== 'braintree') return false
      return true
    })
  }

  _getScholarshipForm() {
    const { contact } = this.props
    return {
      title: 't(New Scholarship)',
      method: 'POST',
      action: `/api/admin/finance/contacts/${contact.id}/scholarships`,
      sections: [
        {
          fields: [
            { label: 't(Amount)', name: 'amount', type: 'moneyfield' }
          ]
        }
      ]
    }
  }

  _handleFailure() {
    this.props.onValid(null, ['Unable to process payment'])
  }

  _handleSuccess(config) {
    this.props.onValid({
      method: config.method,
      ...config.method === 'card' ? {
        payment_method_id: config.card_payment_method_id,
        payment: config.card_payment
      } : config.method === 'ach' ? {
        payment_method_id: config.ach_payment_method_id,
        payment: config.ach_payment
      } : config.method === 'check' ? {
        photo_id: config.photo_id,
        reference: config.reference
      } : config.method === 'ebt' ? {
        reference: config.reference
      } : config.method === 'credit' ? {
        reference: config.credit_id
      } : config.method === 'scholarship' ? {
        reference: config.scholarship_id
      } : {}
    })

  }

  _handleUpdate(config) {
    this.setState({ config })
  }

  _handleValidate() {
    this.formRef.current.submit()
  }

}

const mapResources = (props, context) => ({
  banks: {
    endpoint: '/api/admin/finance/payment_methods',
    query: {
      contact_id: props.contact.id,
      method: 'ach'
    }
  },
  ...!props.cards ? {
    cards: {
      endpoint: '/api/admin/finance/payment_methods',
      query: {
        contact_id: props.contact.id,
        methods: ['applepay','googlepay','card']
      }
    }
  } : {},
  token: '/api/admin/finance/payments/token'
})

export default Container(mapResources)(PaymentField)
