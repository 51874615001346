import DateRangeField from '@apps/dashboards/admin/components/daterangefield'
import ProgramToken from '@apps/crm/admin/tokens/program'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Card extends React.PureComponent {

  static contextTypes = {
    admin: PropTypes.object
  }

  static propTypes = {
    formdata: PropTypes.object,
    props: PropTypes.array,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onNext: PropTypes.func,
    onSave: PropTypes.func
  }

  state = {
    config: {}
  }

  form = null

  _handleBack = this._handleBack.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { config } = this.state
    const daterange_source = _.get(config, 'config.daterange_source')
    return {
      reference: node => this.form = node,
      showHeader: false,
      buttons: [
        { label: 't(Previous)', color: 'blue', basic: true, handler: this._handleBack },
        { label: 't(Finish)', color: 'blue', handler: this._handleSubmit }
      ],
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        { 
          label: 'Scope', 
          type: 'segment', 
          fields: [
            { 
              label: 't(Entity)',
              name: 'config.entity', 
              type: 'dropdown', 
              options: [
                { value: 'all', text: 't(All Entities)' },
                { value: 'advisor', text: 't(Advisor)' },
                { value: 'agency', text: 't(Agency)' },
                { value: 'supplier', text: 't(Supplier)' }
              ], 
              defaultValue: 'all', 
              required: true 
            },
            { 
              label: 't(From Status)',
              name: 'config.from', 
              type: 'dropdown', 
              options: [
                { value: 'pending', text: 't(Pending)' },
                { value: 'configured', text: 't(Configured)' }
              ], 
              defaultValue: 'pending', 
              required: true 
            },
            { 
              label: 't(To Status)',
              name: 'config.to', 
              type: 'dropdown', 
              options: [
                { value: 'configured', text: 't(Configured)' },
                { value: 'active', text: 't(Active)' }
              ], 
              defaultValue: 'configured', 
              required: true 
            }
          ]
        },
        {
          fields: [
            { 
              label: 't(Date Range)', 
              type: 'segment', 
              fields: [
                { 
                  name: 'config.daterange_source', 
                  type: 'radiogroup', 
                  options: [
                    { value: 'dashboard', text: 't(Use dashboard daterange)' },
                    { value: 'custom', text: 't(Custom)' }
                  ], 
                  defaultValue: 'dashboard', 
                  required: true 
                },
                { 
                  name: 'config.daterange', 
                  type: DateRangeField, 
                  show: daterange_source === 'custom', 
                  required: true 
                }
              ] 
            }
          ]
        }
      ]
    }
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSubmit() {
    this.form.submit()
  }

  _handleSuccess(card) {
    this.props.onSave(card)
  }

}

export default Card
