import Logo from '@admin/components/logo'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import pluralize from 'pluralize'
import React from 'react'

const getDuration = (duration) => {
  if(duration % 168 === 0) return pluralize('week', duration / 168, true)
  if(duration % 24 === 0) return pluralize('day', duration / 24, true)
  return pluralize('hour', duration, true)
}

const WorkflowToken = ({ workflow, compact }) =>(
  <div className="automation-workflow-token">
    { compact !== true &&
      <div className="automation-workflow-token-icon">
        <Logo team={ workflow.program } width="24" />
      </div>
    }
    <div className="automation-workflow-token-label">
      { workflow.trigger.full_code === 'maha:team_status_transitioned' ?
        <>
          <T text="t(Contact's team status was transitioned)" /> { workflow.from_team_status &&
            <>from <span className="automation-workflow-token-target">{ workflow.from_team_status }</span></>
          } { workflow.to_team_status &&
            <>to <span className="automation-workflow-token-target">{ workflow.to_team_status }</span></>
          }
        </> : workflow.trigger.full_code === 'maha:team_status_duration' ? <>
          <T text="t(Contact's team had the status)" /> <span className="automation-workflow-token-target">
            { workflow.team_status }
          </span> for <span className="automation-workflow-token-target">
            { getDuration(workflow.duration) }
          </span>
        </> : workflow.trigger.full_code === 'sales:deal_transitioned' ? <>
          <T text="t(Deal was transitioned)" /> { workflow.from_stage &&
            <>from <span className="automation-workflow-token-target">{ workflow.from_stage.title }</span></>
          } { workflow.to_stage &&
            <>to <span className="automation-workflow-token-target">{ workflow.to_stage.title }</span></>
          }
        </> : workflow.trigger.full_code === 'sales:deal_stage_duration' ? <>
          <T text="t(Deal was in the stage)" /> <span className="automation-workflow-token-target">
            { workflow.stage.title }
          </span> for <span className="automation-workflow-token-target">
            { getDuration(workflow.duration) }
          </span>
        </> : <>
          <T text={ workflow.trigger.text } />
          { workflow.email &&
            <> <span className="automation-workflow-token-target">{ workflow.email.title }</span></>
          }
          { workflow.email_campaign &&
            <> <span className="automation-workflow-token-target">{ workflow.email_campaign.title }</span></>
          }
          { workflow.event &&
            <> <span className="automation-workflow-token-target">{ workflow.event.title }</span></>
          }
          { workflow.form &&
            <> <span className="automation-workflow-token-target">{ workflow.form.title }</span></>
          }
          { workflow.list &&
            <> <span className="automation-workflow-token-target">{ workflow.list.title }</span></>
          }
          { workflow.store &&
            <> <span className="automation-workflow-token-target">{ workflow.store.title }</span></>
          }
          { workflow.signup_page &&
            <> <span className="automation-workflow-token-target">{ workflow.signup_page.title }</span></>
          }
          { workflow.pipeline &&
            <> <span className="automation-workflow-token-target">{ workflow.pipeline.title }</span></>
          }
          { workflow.service &&
            <> <span className="automation-workflow-token-target">{ workflow.service.title }</span></>
          }
          { workflow.tag &&
            <> <span className="automation-workflow-token-target">{ workflow.tag.text }</span></>
          }
          { workflow.trigger.code === 'crm:property_updated' && workflow.field &&
            <>: <span className="automation-workflow-token-target">{ workflow.field.name.value }</span></>
          }
        
        </>
      }
      <div className="token-stats">
        <span className={ workflow.status }>{ workflow.status }</span>
        <span>{ workflow.enrolled_count || 0 } enrolled</span>
        <span>{ workflow.active_count || 0 } active</span>
        <span>{ workflow.lost_count || 0 } lost</span>
        <span>{ workflow.converted_count || 0 } converted</span>
        <span>{ workflow.completed_count || 0 } completed</span>
      </div>
    </div>
  </div>
)

WorkflowToken.propTypes = {
  compact: PropTypes.bool,
  workflow: PropTypes.object
}

export default WorkflowToken
