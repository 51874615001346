import DateRangeToken from '@apps/dashboards/admin/tokens/daterange'
import Container from '@admin/components/container'
import Search from '@admin/components/search'
import Loader from '@admin/components/loader'
import Chart from '@admin/components/chart'
import Icon from '@admin/components/icon'
import SocialCampaignToken from './token'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import numeral from 'numeral'
import moment from 'moment'
import React from 'react'
import qs from 'qs'

class Card extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    network: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    config: PropTypes.object,
    daterange: PropTypes.object,
    isExpanded: PropTypes.bool,
    program: PropTypes.object,
    showChart: PropTypes.bool,
    showTable: PropTypes.bool
  }

  state = {
    social_campaign_id: null,
    data: null
  }

  _handleChoose = this._handleChoose.bind(this)

  render() {
    const { daterange, isExpanded, showChart } = this.props
    const { data } = this.state
    const keys = this._getKeys()
    if(!data) return <Loader />
    const metric = this._getMetric(data)
    return (
      <div className="maha-dashboard-card-body">
        { isExpanded && 
          <div className="maha-dashboard-card-sidebar">
            <Search { ...this._getSearch() } />
          </div>
        }
        <div className="maha-dashboard-card-detail">
          <div className="maha-dashboard-card-detail-header">
            <div className="maha-dashboard-card-detail-title">
              <T text={ this._getLabel(keys.title) } />
            </div>
            <div className="maha-dashboard-card-detail-subtitle">
              <DateRangeToken daterange={ daterange } />
            </div>
            <div className="maha-dashboard-card-detail-metric">
              <div className="maha-dashboard-card-detail-metric-inner">
                <div className="maha-dashboard-card-detail-metric-text">
                  { metric.value }
                </div>
                { metric.delta &&
                  <div className={`maha-dashboard-card-detail-metric-delta ${ metric.delta.direction }`}>
                    <Icon icon={`caret-${ metric.delta.direction }`} /> { metric.delta.percent }
                  </div>
                }
              </div>
            </div>
          </div>
          { showChart &&
            <div className="maha-dashboard-card-detail-chart">
              <Chart { ...this._getChart(keys, data) } />
            </div>
          }
          { isExpanded &&
            <div className="maha-dashboard-card-detail-table">
              <table>
                <thead>
                  <tr>
                    <th><T text="t(Date)" /></th>
                    <th>Instagram</th>
                    <th>Facebook</th>
                    <th>Combined</th>
                  </tr>
                </thead>
                <tbody>
                  { data.map((record, index) => (
                    <tr key={`record_${index}`} onClick={ this._handleClick.bind(this, keys.metric, record) }>
                      <td>{ this._getRange(record) }</td>
                      <td>
                        <div className="maha-dashboard-card-detail-amount">{ record.instagram.current }</div>
                        { record.instagram.delta &&
                          <div className={`maha-dashboard-card-detail-delta ${record.instagram.delta.direction}`}>
                            <Icon icon={`caret-${ record.instagram.delta.direction }`} />
                            { numeral(record.instagram.delta.percent).format('0.00%') }
                          </div>
                        }
                      </td>
                      <td>
                        <div className="maha-dashboard-card-detail-amount">{ record.facebook.current }</div>
                        { record.facebook.delta &&
                          <div className={`maha-dashboard-card-detail-delta ${record.facebook.delta.direction}`}>
                            <Icon icon={`caret-${ record.facebook.delta.direction }`} />
                            { numeral(record.facebook.delta.percent).format('0.00%') }
                          </div>
                        }
                      </td>
                      <td>
                        <div className="maha-dashboard-card-detail-amount">{ record.all.current }</div>
                        { record.all.delta &&
                          <div className={`maha-dashboard-card-detail-delta ${record.all.delta.direction}`}>
                            <Icon icon={`caret-${ record.all.delta.direction }`} />
                            { numeral(record.all.delta.percent).format('0.00%') }
                          </div>
                        }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          }
        </div>
      </div>
    )
  }

  componentDidMount() {
    this._handleFetch()
  }

  componentDidUpdate(prevProps, prevState) {
    const { social_campaign_id } = this.state
    if(social_campaign_id !== prevState.social_campaign_id) {
      this._handleFetch()
    }
  }

  _getChart(keys, data) {
    const { config, isExpanded } = this.props
    return {
      type: config.type,
      datasets: [
        {
          color: '#1877F2',
          label: 'Facebook',
          points: data.map(record => ({
            x: this._getX(record),
            y: record.facebook.current
          }))
        },
        {
          color: '#E4405F',
          label: 'Instagram',
          points: data.map(record => ({
            x: this._getX(record),
            y: record.instagram.current
          }))
        }
      ],
      options: {
        aspectRatio: isExpanded ? 2 : 1.5,
        points: config.points,
        tension: config.tension,
        grid: isExpanded,
        stacked: true
      }
    }
  }

  _getKeys() {
    const { metric } = this.props.config
    return {
      metric,
      ...metric === 'comments' ? {
        title: 't(Comments)'
      } : metric === 'clicks' ? {
        title: 't(Clicks)'
      } : metric === 'engagement' ? {
        title: 't(Engagement)'
      } : metric === 'impressions' ? {
        title: 't(Impressions)'
      } : metric === 'reach' ? {
        title: 't(Reach)'
      } : metric === 'shares' ? {
        title: 't(Shares)'
      } : metric === 'saves' ? {
        title: 't(Saves)'
      } : metric === 'video_views' ? {
        title: 't(Video Views)'
      } : metric === 'reactions' ? {
        title: 't(Reactions)'
      } : {}
    }
  }

  _getLabel(label) {
    const { config, program } = this.props
    const { team } = this.context.admin
    if(config.title) return config.title
    if(config.scope === 'program' && team.has_programs) return `${label} - ${program.title}`
    return label
  }

  _getMetric(data) {
    const previous_data = data.filter(record => record.all.previous > 0)
    const current_data = data.filter(record => record.all.current > 0)
    const previous = previous_data.reduce((total, record) => total + record.all.previous, 0)
    const current = current_data.reduce((total, record) => total + record.all.current, 0)
    const amount = current - previous
    const percent = amount === 0 ? null : previous === 0 ? 1 : Math.abs((current - previous) / previous)
    return {
      value: numeral(current).format('0,0'),
      delta: percent > 0 ? {
        direction: amount < 0 ? 'down' : 'up',
        percent: percent && percent !== 0 ? numeral(percent).format('0.00%') : null
      } : null
    }
  }

  _getRange(record) {
    const { step } = this.props.daterange
    const { end, start } = record
    if(step === 'day') return moment(start).format('MMM D, YYYY')
    if(step === 'week') return `${ moment(start).format('MMM D') } - ${ moment(end).format('MMM D, YYYY') }`
    if(step === 'month') return moment(start).format('MMM YYYY')
    if(step === 'quarter') return `${ moment(start).format('[Q]Q YYYY') } (${ moment(start).format('MMM D') } - ${ moment(end).format('MMM D') })`
    if(step === 'year') return moment(start).format('YYYY')
  }

  _getSearch() {
    const { config, daterange } = this.props
    return {
      endpoint: '/api/admin/dashboards/campaigns/social_campaign_metric/campaigns',
      valueKey: 'id',
      textKey: 'title',
      deselectable: true,
      defaultQuery: {
        metric: config.metric,
        program_id: config.program_id,
        daterange 
      },
      entity: 't(social campaign)',
      format: (social_campaign) => (
        <SocialCampaignToken social_campaign={ social_campaign }/>
      ),
      onChange: this._handleChoose
    }
  }

  _getX(record) {
    const { step } = this.props.daterange
    const { end, start } = record
    if(step === 'day') return moment(start).format('M/D/YY')
    if(step === 'week') return `${ moment(start).format('M/D/YY') } - ${ moment(end).format('M/D/YY') }`
    if(step === 'month') return moment(start).format('MMM YYYY')
    if(step === 'quarter') return moment(start).format('[Q]Q YYYY')
    if(step === 'year') return moment(start).format('YYYY')
  }

  _handleChoose(social_campaign_id) {
    this.setState({ 
      social_campaign_id: social_campaign_id || null
    })
  }

  _handleClick(metric, record) {
    const { program } = this.props
    const { social_campaign_id } = this.state
    const filter = qs.stringify({
      metric,
      ...program ? { program_id: program.id } : {},
      social_campaign_id,
      start_date: moment(record.start).format('YYYY-MM-DD'),
      end_date: moment(record.end).format('YYYY-MM-DD')
    }, { encode: false })
    this.context.router.push(`/admin/dashboards/campaigns/social_campaign_metric?${filter}`)
  }

  _handleFetch() {
    const { config, daterange } = this.props
    const { social_campaign_id } = this.state
    this.context.network.request({
      endpoint: '/api/admin/dashboards/campaigns/social_campaign_metric/card',
      method: 'GET',
      filter: {
        metric: config.metric,
        social_campaign_id,
        program_id: config.program_id,
        daterange 
      },
      onSuccess: ({ data }) => {
        this.setState({ data })
      }
    })
  }

}

const mapResources = (props, context) => ({
  ...props.config.program_id ? {
    program: `/api/admin/team/programs/${props.config.program_id}`
  } : {}
})

export default Container(mapResources)(Card)
