import axios from 'axios'
import fs from 'fs'

export const getConstrainedDimensions = (width, height, boundingWidth, boundingHeight) => {
  const constrainedWidth = width <= boundingWidth && height <= boundingHeight ? width : Math.min(boundingWidth, width, (boundingHeight * width) / height)
  const constrainedHeight = (constrainedWidth * height) / width
  return { 
    width: Math.floor(constrainedWidth), 
    height: Math.floor(constrainedHeight)
  }
}

export const getConstrainedWidth = (width, height, boundingWidth, boundingHeight) => {
  const constrained = getConstrainedDimensions(width, height, boundingWidth, boundingHeight)
  return constrained.width
}

export const getBase64Image = async (path) => {
  const data = path.startsWith('http') ? await axios.get(path, { 
    responseType: 'arraybuffer' 
  }).then(response => Buffer.from(response.data)) : fs.readFileSync(path)
  return data.toString('base64')
}
