import RecipientToken from '@apps/campaigns/admin/tokens/recipient'
import Results from '@admin/components/criteria_designer/results'
import ModalPanel from '@admin/components/modal_panel'
import Container from '@admin/components/container'
import Infinite from '@admin/components/infinite'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import pluralize from 'pluralize'
import React from 'react'

class Truevail extends React.PureComponent {

  static contextTypes = {
    form: PropTypes.object
  }

  static propTypes = {
    agencies: PropTypes.array,
    channel: PropTypes.string,
    defaultValue: PropTypes.object,
    endpoint: PropTypes.string,
    fields: PropTypes.array,
    instructions: PropTypes.any,
    plans: PropTypes.array,
    program: PropTypes.object,
    program_id: PropTypes.number,
    purpose: PropTypes.string,
    onDone: PropTypes.func
  }

  state = {
    truevail: null
  }

  _handleBack = this._handleBack.bind(this)
  _handleDone = this._handleDone.bind(this)
  _handleUpdate = this._handleUpdate.bind(this)

  render() {
    if(!this.state.truevail) return null
    return (
      <ModalPanel { ...this._getPanel() }>
        <div className="maha-criteria-designer">
          <div className="maha-criteria-designer-filter">
            <Form { ...this._getForm() } />
          </div>
          <div className="maha-criteria-designer-results">
            <Infinite { ...this._getInfinite() } />
          </div>
        </div>
      </ModalPanel>
    )
  }

  componentDidMount() {
    const truevail = this._getDefaultValue()
    this.setState({ truevail })
  }

  _getDefaultValue() {
    const { defaultValue } = this.props
    return {
      agency_id: 'null',
      last_online_at: 'null',
      plan_id: 'null',
      subscriptions: 'null',
      teams: 'null',
      teams_status: 'active',
      users: 'active',
      ...defaultValue?.truevail || {}        
    }
  }

  _getForm() {
    const { agencies, plans } = this.props
    const { truevail } = this.state
    return {
      showHeader: false,
      onChange: this._handleUpdate,
      sections: [
        {
          fields: [
            { label: 't(Send to)', type: 'segment', fields: [
              { name: 'users', type: 'dropdown', options: [
                { value: 'active', text: 't(all active users)' },
                { value: 'primary', text: 't(all primary users)' }              
              ], defaultValue: truevail.users },
              { name: 'teams', type: 'dropdown', options: [
                { value: 'null', text: 't(of all teams)' },
                { value: 'advisor', text: 't(of all advisors)' },
                { value: 'agency', text: 't(of all agencies)' },
                { value: 'supplier', text: 't(of all suppliers)' }
              ], defaultValue: truevail.teams },
              { name: 'teams_status', type: 'dropdown', options: [
                { value: 'null', text: 't(with any status)' },
                { value: 'active', text: 't(with an active status)' },
                { value: 'pending', text: 't(with a pending status)' },
                { value: 'configured', text: 't(with a configured status)' },
                { value: 'unconfigured', text: 't(with a unconfigured status)' },
                { value: 'paused', text: 't(with a paused status)' },
                { value: 'canceled', text: 't(with a canceled status)' }
              ], defaultValue: 'active' },
              ...truevail.teams === 'advisor' ? [
                { name: 'agency_id', type: 'dropdown', options: [
                  { value: 'null', text: 't(owned by any agency)' },
                  ...agencies.map(agency => ({
                    value: agency.id,
                    text: `owned by ${agency.organization}`
                  }))
                ], defaultValue: 'null' },
                { name: 'subscriptions', type: 'dropdown', options: [
                  { value: 'null', text: 't(with or without any active subscriptions)' },
                  { value: 'active', text: 't(with any active subscription)' },
                  { value: 'email', text: 't(with an active email subscription)' },
                  { value: 'social', text: 't(with an active social subscription)' },
                  { value: 'legacy_website', text: 't(with an active legacy website subscription)' }
                ], defaultValue: 'null' }
              ] : [],
              { name: 'plan_id', type: 'dropdown', options: [
                { value: 'null', text: 't(with or without any active billing plan)' },
                ...plans.map(plan => ({
                  value: plan.id,
                  text: `with the '${plan.title}' billing plan`
                }))
              ], defaultValue: 'null' },
              { name: 'last_online_at', type: 'dropdown', options: [
                { value: 'null', text: 't(whether or not they have ever signed in)' },
                { value: 'ever', text: 't(who have ever signed in)' },
                { value: 'recently', text: 't(who have signed in during the last 30 days)' },
                { value: 'nonrecently', text: 't(who haven\'t signed in for 30 days)' },
                { value: 'never', text: 't(who have never signed in)' }
              ], defaultValue: 'null' }
            ] }
          ]
        }
      ]
    }
  }

  _getInfinite() {
    const { channel, endpoint, program_id, purpose } = this.props
    const { truevail } = this.state
    return {
      defaultQuery: {
        channel,
        program_id,
        purpose,
        strategy: 'truevail'
      },
      query: {
        truevail
      },
      endpoint,
      entity: 'contact',
      layout: Results,
      props: this._getResults(),
      footer: ({ all, total }) => `Matching ${total} of ${pluralize('contacts', all, true)}`
    }
  }

  _getPanel() {
    const { instructions } = this.props
    return {
      title: 't(Select truevail)',
      instructions,
      leftItems: [
        { icon: 'chevron-left', handler: this._handleBack }
      ],
      rightItems: [
        { label: 't(Done)', handler: this._handleDone }
      ]
    }
  }

  _getResults() {
    return {
      format: (recipient) => <RecipientToken recipient={ recipient } />
    }
  }

  _handleBack() {
    this.context.form.pop()
  }

  _handleDone() {
    const { truevail } = this.state
    this.props.onDone({ truevail })
    this.context.form.pop()
  }

  _handleType(q) {
    this.setState({ q })
  }

  _handleUpdate(truevail) {
    this.setState({ 
      truevail: {
        ...this.state.truevail,
        ...truevail
      } })
  }

}

const mapResources = (props, context) => ({
  agencies: '/api/admin/truevail/admin/agencies',
  plans: '/api/admin/subscriptions/services/3/plans'
})

export default Container(mapResources)(Truevail)
