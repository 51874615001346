import MJSONScreenshot from '@admin/components/mjson_screenshot'
import Date from '@admin/components/date'
import PropTypes from 'prop-types'
import React from 'react'

const getScreenshot = (entity) => ({
  engine: entity.engine === 'mblocks' ? 'mblocks' : 'mjson',
  entity: `campaigns_email_campaigns/${entity.code}`,
  width: 100,
  height: 75,
  screenshoted_at: entity.screenshoted_at
})

const EmailCampaignToken = ({ email_campaign }) => (
  <div className="campaign-token">
    <div className="campaign-token-preview">
      <div className="campaign-token-preview-inner">
        <MJSONScreenshot { ...getScreenshot(email_campaign) } />
      </div>
    </div>
    <div className="campaign-token-label">
      <div className="token-link">
        { email_campaign.title }
      </div>
      <div className="token-stats">
        { email_campaign.team.title }
      </div>
      <div className="token-stats">
        <span className={ email_campaign.status }>{ email_campaign.status }</span>
        { email_campaign.status === 'scheduled' &&
          <span><Date datetime={ email_campaign.send_at } /></span>
        }
      </div>
    </div>
  </div>
)

EmailCampaignToken.propTypes = {
  email_campaign: PropTypes.object
}

export default EmailCampaignToken
