import AdvisorSignup from './advisor_signup'
import PostThumbnail from './post_thumbnail'
import PostHeader from './post_header'
import Accordian from './accordian'
import Countdown from './countdown'
import IconList from './icon_list'
import Contact from './contact'
import Gallery from './gallery'
import Sidebar from './sidebar'
import Buttons from './buttons'
import Number from './number'
import Social from './social'
import Button from './button'
import Image from './image'
import Video from './video'
import Quote from './quote'
import Card from './card'
import Code from './code'
import Form from './form'
import Blog from './blog'
import Menu from './menu'
import Text from './text'
import Icon from './icon'
import List from './list'
import Map from './map'

const blocks = [
  { type: 'accordian', label: 't(Accordian)', form: Accordian, noun_project_id: 309744 },
  { type: 'advisor_signup', label: 't(Advisor Signup)', form: AdvisorSignup, noun_project_id: 309684, access: {
    'team.id': { $eq: 41 }
  } },
  // { type: 'blog', label: 't(Blog)', form: Blog, icon: '310971' },
  { type: 'button', label: 't(Button)', form: Button, noun_project_id: 309746 },
  { type: 'buttons', label: 't(Buttons)', form: Buttons, noun_project_id: 309746 },
  { type: 'form', label: 't(Form)', form: Form, noun_project_id: 309644 },
  { type: 'card', label: 't(Hover Card)', form: Card, noun_project_id: 309798 },
  { type: 'code', label: 't(Code)', form: Code, noun_project_id: 310739 },
  { type: 'contact', label: 't(Contact)', form: Contact, noun_project_id: 309728 },
  { type: 'countdown', label: 't(Countdown)', form: Countdown, noun_project_id: 309866 },
  { type: 'gallery', label: 't(Gallery)', form: Gallery, noun_project_id: 43769 },
  { type: 'image', label: 't(Image)', form: Image, noun_project_id: 309601 },
  { type: 'icon', label: 't(Icon)', form: Icon, noun_project_id: 310630 },
  // { type: 'icon_list', label: 't(Icon List)', form: IconList },
  // { type: 'list', label: 't(List)', form: List },
  { type: 'map', label: 't(Map)', form: Map, noun_project_id: 310870 },
  // { type: 'menu', label: 't(Menu)', form: Menu },
  { type: 'number', label: 't(Number)', form: Number, noun_project_id: 4808956 },
  // { type: 'post_thumbnail', label: 't(Post Thumbnail)', form: PostThumbnail },
  // { type: 'post_header', label: 't(Post Header)', form: PostHeader },
  { type: 'quote', label: 't(Quote)', form: Quote, noun_project_id: 310964 },
  // { type: 'sidebar', label: 't(Sidebar)', form: Sidebar },
  { type: 'social', label: 't(Social)', form: Social, social: 'facebook' },
  { type: 'text', label: 't(Text)', form: Text, noun_project_id: 310909 },
  { type: 'video', label: 't(Video)', form: Video, noun_project_id: 309684 }
]

export default blocks
