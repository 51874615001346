import EngagementToken from '@apps/maha/admin/tokens/engagement'
import Panel from '@admin/components/panel'
import moment from 'moment'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Engagement)',
  collection: {
    endpoint: '/api/admin/truevail/admin/deliverability/engagement',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Date)', key: 'date', primary: true, padded: true, format: 'date' },
      { label: 't(Emails)', key: 'emails_count', collapsing: true, align: 'right' },
      { label: 't(Open)', key: 'open_rate', collapsing: true, format: 'rate' },
      { label: 't(Click)', key: 'click_rate', collapsing: true, format: 'rate' },
      { label: 't(Soft Bounce)', key: 'soft_bounce_rate', collapsing: true, format: 'rate' },
      { label: 't(Hard Bounce)', key: 'hard_bounce_rate', collapsing: true, format: 'rate' },
      { label: 't(Complaint)', key: 'complaint_rate', collapsing: true, format: 'rate' },
      { label: 't(Score)', key: 'score', collapsing: true, align: 'right' }
    ],
    chart: {
      type: 'line',
      datasets: (records) => [
        { 
          label: 'Open',
          points: records.map(record => ({
            x: moment(record.date).format('YYYY-MM-DD'),
            y: Math.round(record.open_rate * 10000) / 100
          })).sort((a,b) => a.x < b.x ? -1 : 1)
        },
        { 
          label: 'Click',
          points: records.map(record => ({
            x: moment(record.date).format('YYYY-MM-DD'),
            y: Math.round(record.click_rate * 10000) / 100
          })).sort((a,b) => a.x < b.x ? -1 : 1)
        },
        { 
          label: 'Soft Bounce',
          points: records.map(record => ({
            x: moment(record.date).format('YYYY-MM-DD'),
            y: Math.round(record.soft_bounce_rate * 10000) / 100
          })).sort((a,b) => a.x < b.x ? -1 : 1)
        },
        { 
          label: 'Hard Bounce',
          points: records.map(record => ({
            x: moment(record.date).format('YYYY-MM-DD'),
            y: Math.round(record.hard_bounce_rate * 10000) / 100
          })).sort((a,b) => a.x < b.x ? -1 : 1)
        },
        { 
          label: 'Complaint',
          points: records.map(record => ({
            x: moment(record.date).format('YYYY-MM-DD'),
            y: Math.round(record.complaint_rate * 10000) / 100
          })).sort((a,b) => a.x < b.x ? -1 : 1)
        }
      ],
      options: {
        yscale: {
          display: true,
          min: 0,
          max: 100
        },
        legend: {
          display: true,
          position: 'bottom'
        },
        points: 'show'
      }
    },
    criteria: {
      fields: [
        {
          label: 't(Fields)',
          fields: [
            { name: 't(Top Level Domain)', key: 'tld_id', type: 'select', endpoint: '/api/admin/truevail/admin/domains', valueKey: 'id', textKey: 'name', filter: { tld_id: { $eq: 'null' } } },
            { name: 't(Subdomain)', key: 'domain_id', type: 'select', endpoint: '/api/admin/truevail/admin/domains', valueKey: 'id', textKey: 'name',filter: { tld_id: { $neq: 'null' } } },
            { name: 't(Segment)', key: 'segment_id', type: 'select', endpoint: '/api/admin/truevail/admin/segments', valueKey: 'id', textKey: 'text' }
          ]
        }
      ]
    },
    defaultSort: { key: 'date', order: 'desc' },
    empty: {
      icon: 'line-chart',
      title: 't(No Engagements)',
      text: 't(There are no engagement)'
    },
    entity: 'engagement',
    onClick: (record) => context.router.push(`/admin/truevail/admin/sent/domain_segments/${props.domain_segment.id}/day/${moment(record.date).format('YYYY-MM-DD')}`)
  }
})

export default Panel(null, mapPropsToPanel)
