import { getMessaging, getToken, isSupported } from 'firebase/messaging'
import { ProviderContext } from '@admin/components/provider'
import { initializeApp } from 'firebase/app'
import PropTypes from 'prop-types'
import Pasteur from 'pasteur'
import Offer from './offer'
import React from 'react'

export const HostContext = React.createContext()
HostContext.displayName = 'HostContext'

export const useHostContext = () => React.useContext(HostContext)

const firebaseConfig = {
  apiKey: process.env.FIREBASE_API_KEY,
  projectId: process.env.FIREBASE_PROJECT_ID,
  messagingSenderId: process.env.FIREBASE_MESSAGE_SENDER_ID,
  appId: process.env.FIREBASE_APP_ID,
  host: process.env.ADMIN_HOST
}

class Host extends React.Component {

  static childContextTypes = {
    host: PropTypes.object
  }

  static contextTypes = {
    device: PropTypes.object
  }

  static propTypes = {
    children: PropTypes.any,
    provider: PropTypes.object
  }

  state = {
    count: 0,
    host: null,
    title: null
  }

  linkRef = React.createRef()
  pasteur = null
  store = null

  _handleFocus = this._handleFocus.bind(this)
  _handleGetContacts = this._handleGetContacts.bind(this)
  _handleGetNotificationPermission = this._handleGetNotificationPermission.bind(this)
  _handleGetPushToken = this._handleGetPushToken.bind(this)
  _handleGetType = this._handleGetType.bind(this)
  _handleHasFocus = this._handleHasFocus.bind(this)
  _handleOpenWindow = this._handleOpenWindow.bind(this)
  _handleRequestNotificationPermission = this._handleRequestNotificationPermission.bind(this)
  _handleSetCount = this._handleSetCount.bind(this)
  _handleSetTitle = this._handleSetTitle.bind(this)
  _handleSignin = this._handleSignin.bind(this)
  _handleVibrate = this._handleVibrate.bind(this)

  constructor() {
    super()
    this.pasteur = new Pasteur({
      window,
      target: window.parent,
      name: 'mahaapp',
      targetName: 'mahahost'
    })
  }

  render() {
    const { host } = this.state
    if(!host) return null
    return (
      <HostContext.Provider value={ this.getChildContext() }>
        <div className={ this._getClass() }>
          <Offer>
            { this.props.children }
          </Offer>
          { host === 'browser' &&
            <a ref={ this.linkRef } target="_blank" />
          }
        </div>
      </HostContext.Provider>
    )
  }

  componentDidMount() {
    this._handleSetHost()
    this._handleUpdateHead()
  }

  componentDidUpdate(prevProps, prevState) {
    const { provider } = this.props
    const { count, title } = this.state
    if(!_.isEqual(provider, prevProps.provider)) {
      this._handleUpdateHead()
    }
    if(count !== prevState.count) {
      this._handleUpdateHead()
    }
    if(title !== prevState.title) {
      this._handleUpdateHead()
    }
  }

  getChildContext() {
    return {
      host: {
        contacts: {
          get: this._handleGetContacts
        },
        device: {
          focus: this._handleFocus,
          getFocus: this._handleHasFocus,
          getType: this._handleGetType,
          setCount: this._handleSetCount,
          setTitle: this._handleSetTitle,
          vibrate: this._handleVibrate
        },
        notifications: {
          getPermission: this._handleGetNotificationPermission,
          getToken: this._handleGetPushToken,
          requestPermission: this._handleRequestNotificationPermission
        },
        window: {
          open: this._handleOpenWindow,
          signin: this._handleSignin
        }
      }
    }
  }

  _getClass() {
    const { host } = this.state
    const classes = ['maha-host']
    classes.push(host)
    return classes.join(' ')
  }

  _getFavicon(size) {
    const { provider } = this.props
    // const { count } = this.state
    // return `/images/icons/favicon-${count > 0 ? 'x' : '0'}.png`
    return `${provider.cdn_host}/imagecache/w=${size}&h=${size}${provider.logo}`
  }

  _getHost() {
    if(navigator.userAgent.match(/Cordova/) !== null) {
      return 'mobile'
    } else if(navigator.userAgent.match(/Electron/) !== null) {
      return 'desktop'
    } else {
      return 'browser'
    }
  }

  _getTitle() {
    const { provider } = this.props
    const { count, title } = this.state
    return [
      ...title ? [`${title} |`] : [],
      provider.title,
      ...count > 0 ? [`(${count})`] : []
    ].join(' ')
  }

  _handleFocus() {
    const { host } = this.state
    if(host === 'browser') {
      window.focus()
    } else if(host === 'desktop') {
      this.pasteur.send('focus')
    }
  }

  _handleGetType() {
    return this.state.host
  }

  async _handleGetContacts() {
    const { host } = this.state
    if(host !== 'mobile') return null
    return new Promise((resolve, reject) => {
      this.pasteur.send('getContacts', null, (contacts) => {
        resolve(contacts)
      }, (err) => {
        reject(err)
      })
    })
  }

  async _handleGetNotificationPermission() {
    const { host } = this.state
    if(host === 'desktop') return 'granted'
    if(host === 'browser' && !window.Notification) return 'denied'
    return new Promise((resolve, reject) => {
      if(host === 'browser' && window.Notification) {
        resolve(window.Notification.permission)
      } else if(host === 'mobile'){
        this.pasteur.send('getNotificationPermission', null, resolve, reject)
      }
    })
  }

  async _handleGetPushToken() {
    const { host } = this.state
    if(host === 'desktop') return null
    if(host === 'browser' && !window.Notification) return null
    return new Promise((resolve, reject) => {
      if(host === 'mobile') {
        return this.pasteur.send('getPushToken', null, resolve, reject)
      } else if(host ==='browser') {
        const firebase = initializeApp(firebaseConfig)
        const messaging = getMessaging(firebase)
        if(!navigator.serviceWorker || !isSupported()) resolve(null)
        const sw = `/sw.js?host=${process.env.ADMIN_HOST}`
        navigator.serviceWorker.register(sw).then(serviceWorkerRegistration => {
          getToken(messaging, {
            serviceWorkerRegistration,
            vapidKey: process.env.FIREBASE_WEB_PUSH_PUBLIC_KEY
          }).then(resolve).catch(reject)
        }).catch(reject)
      }
    })
  }

  _handleHasFocus() {
    return document.hasFocus()
  }

  _handleOpenWindow(url) {
    const { device } = this.context
    const { host } = this.state
    if(host === 'browser') {
      if(device.os === 'ios') return window.location.href = url
      this.linkRef.current.href = url
      this.linkRef.current.click()
    } else {
      this.pasteur.send('openWindow', url)
    }
  }

  async _handleRequestNotificationPermission() {
    const { host } = this.state
    if(host === 'desktop') return 'granted'
    if(host === 'browser' && !window.Notification) return 'denied'
    return new Promise((resolve, reject) => {
      if(host === 'browser') {
        window.Notification.requestPermission().then(resolve).catch(reject)
      } else if(host === 'mobile') {
        this.pasteur.send('requestNotificationPermission', null, resolve, reject)
      }
    })
  }

  _handleSetCount(count) {
    const { host } = this.state
    this.setState({ count })
    if(host === 'browser') return
    this.pasteur.send('setBadge', count)
  }

  _handleSetHost() {
    this.setState({
      host: this._getHost()
    })
  }

  _handleSetTitle(title) {
    this.setState({ title })
  }

  _handleSignin(url) {
    const { device } = this.context
    const { host } = this.state
    if(host === 'browser') {
      if(device.os === 'ios') return window.location.href = url
      this.linkRef.current.href = url
      this.linkRef.current.click()
    } else {
      this.pasteur.send('openWindow', url)
    }
  }

  _handleUpdateHead() {
    const { provider } = this.props
    const sizes = [57,60,72,76,114,120,144,152,180,192,384,512]
    sizes.map(size => {
      document.querySelector(`link[sizes="${size}x${size}"]`).href = this._getFavicon(size)
    })
    document.querySelector('link[rel="apple-touch-startup-image"]').href = this._getFavicon(192)
    document.querySelector('link[rel="shortcut icon"]').href = this._getFavicon(32)
    document.querySelector('link[rel="shortcut icon"]').href = this._getFavicon(32)
    document.querySelector('title').text = this._getTitle()
    document.querySelector('meta[name="apple-mobile-web-app-title"]').text = provider.title
    document.querySelector('meta[name="application-name"]').text = provider.title
    document.querySelector('title').text = this._getTitle()
  }

  _handleVibrate() {
    const { host } = this.state
    if(host !== 'mobile') return null
    this.pasteur.send('vibrate', 500)
  }

}

const HostWrapper = (props) => (
  <ProviderContext.Consumer>
    { provider => (
      <Host { ...props } provider={ provider.provider } />
    ) }
  </ProviderContext.Consumer>
)

export default HostWrapper
