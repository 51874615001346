import ToField from '@apps/campaigns/admin/components/tofield'
import Form from '@admin/components/form'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'
import moment from 'moment'

class Send extends React.Component {

  static contextTypes = {
    modal: PropTypes.object
  }

  static propTypes = {
    email_campaign: PropTypes.object
  }

  state = {
    strategy: 'now'
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleChangeField = this._handleChangeField.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { strategy } = this.state
    const { email_campaign } = this.props
    return {
      title: 't(Send Campaign)',
      method: 'PATCH',
      endpoint: `/api/admin/campaigns/email/${email_campaign.id}/edit`,
      action: `/api/admin/campaigns/email/${email_campaign.id}/send`,
      saveText: strategy === 'now' ? 't(Send)' : 't(Schedule)',
      onCancel: this._handleCancel,
      onChangeField: this._handleChangeField,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { 
              label: 't(Recipients)', 
              name: 'to', 
              type: ToField, 
              program_id: email_campaign.program.id, 
              channel: 'email', 
              required: true, 
              purpose: email_campaign.purpose, 
              store: email_campaign.store, 
              event: email_campaign.event, 
              form: email_campaign.form, 
              pipeline: email_campaign.pipeline, 
              service: email_campaign.service, 
              webinar: email_campaign.webinar, 
              defaultValue: email_campaign.to 
            },
            { 
              label: 't(Send At)', 
              type: 'segment', 
              fields: [
                { 
                  name: 'strategy', 
                  type: 'radiogroup', 
                  options: [
                    { value: 'now', text: 't(Send immediately)' },
                    { value: 'schedule', text: 't(Schedule Delivery)' }
                  ], 
                  required: true, 
                  defaultValue: strategy 
                },
                { 
                  name: 'send_at', 
                  type: 'datetimefield', 
                  show: strategy === 'schedule', 
                  required: true 
                }
              ] 
            }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleChangeField(key, value) {
    if(key === 'strategy') {
      this.setState({
        strategy: value
      })
    }
  }

  _handleSuccess(result) {
    this.context.modal.close()
  }

}

export default Send
