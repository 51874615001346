import CallActivities from '@apps/phone/admin/components/call_activities'
import PhoneNumber from '@admin/components/phone_number'
import Button from '@admin/components/button'
import Date from '@admin/components/date'
import Icon from '@admin/components/icon'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import moment from 'moment'
import React from 'react'

const failures = {
  no_contact_email: 't(This contact has no email address)',
  no_contact_phone: 't(This contact does not have a mobile phone number)',
  no_user_phone: 't(This user does not have a mobile phone number)'
}

const blocks = {
  consent: { icon: 'thumbs-up' },
  deal: { icon: 'handshake-o' },
  dial: { icon: 'phone' },
  dialbyextension: { icon: 'phone' },
  dialbyname: { icon: 'phone' },
  dialmenu: { icon: 'phone' },
  email: { icon: 'envelope' },
  goal: { icon: 'flag' },
  ifthen: { icon: 'sitemap' },
  internal_sms: { icon: 'comment' },
  list: { icon: 'th-list' },
  message: { icon: 'comment' },
  notification: { icon: 'bell' },
  play: { icon: 'play' },
  property: { icon: 'id-card' },
  question: { icon: 'question' },
  record: { icon: 'microphone' },
  say: { icon: 'volume-control-phone' },
  set: { icon: 'times' },
  sms: { icon: 'comment' },
  task: { icon: 'check' },
  timeofday: { icon: 'clock-o' },
  voicemail: { icon: 'voicemail' },
  wait: { icon: 'clock-o' }
}

class Action extends React.PureComponent {

  static contextTypes = {
    admin: PropTypes.object
  }

  static propTypes = {
    action: PropTypes.object,
    call: PropTypes.object,
    enrollment: PropTypes.object
  }

  state = {
    expanded: false
  }

  _handleToggle = this._handleToggle.bind(this)

  render() {
    const { action, call } = this.props
    const { data, error, failure_code, step } = action
    const { expanded } = this.state
    return (
      <div className="crm-workflow-action">
        <div className="crm-workflow-action-icon">
          { (error || failure_code) ?
            <div className="crm-workflow-action-action ending" title={ error }>
              <Icon icon="times" />
            </div> :
            <div className={`crm-workflow-action-action ${action.step.type}`}>
              <Icon icon={ blocks[action.step.action].icon } />
            </div>
          }
        </div>
        <div className="crm-workflow-action-details">
          <div className="crm-workflow-action-header" onClick={ this._handleToggle }>
            <div className="crm-workflow-action-label">
              { (!error && !failure_code) &&
                <Icon icon={`${ this._getIcon() }`} />
              }
              <strong>
                <T text={ this._getAction(action) } />
              </strong>
              { error &&
                <>
                  : <T text="t(There was an internal error)" />
                </>
              }
              { failure_code &&
                <>
                  : <T text={ failures[failure_code] } />
                </>
              }
            </div>
            <div className="crm-workflow-action-timestamp">
              <Date datetime={ action.created_at } />
            </div>
          </div>
          { expanded &&
            <div className="crm-workflow-action-body">
              { step.type === 'contact' && step.action === 'deal' && data.deal &&
                <span>
                  Create deal <Button { ...this._getDeal(data.deal) } />
                </span>
              }
              { step.type === 'contact' && step.action === 'task' && data.task &&
                <span>
                  Create task <Button { ...this._getTask(data.task) } />
                </span>
              }
              { step.type === 'contact' && step.action === 'consent' && data.program &&
                <span>
                  { data.action === 'add' ? 'Opted in to' : 'Oped out of' } { data.channel_type } channel
                </span>
              }
              { step.type === 'contact' && step.action === 'list' && data.list &&
                <span>
                  { data.action === 'add' ? 'Added to' : 'Removed from' } { data.list.title }
                </span>
              }
              { step.type === 'contact' && step.action === 'consent' &&
                <span>
                  { data.action === 'add' ? 'Added consent for ' : 'Removed consent for ' } { data.value }
                </span>
              }
              { step.type === 'control' && step.action === 'ifthen' &&
                <span>BRANCH: { data.branch }</span>
              }
              { step.type === 'control' && step.action === 'timeofday' &&
                <span>TIMEBLOCK: { data.timeblock }</span>
              }
              { step.type === 'control' && step.action === 'set' &&
                <span>{ data.token } = { data.value }</span>
              }
              { step.type === 'control' && step.action === 'wait' &&
                <span>Wait until { moment(action.waited_until).format('MM/DD/YY, hh:mmA') }</span>
              }
              { step.type === 'sms' && step.action === 'question' && data.sms &&
                <span>{ data.sms.body }</span>
              }
              { step.type === 'sms' && step.action === 'question' && data &&
                <span>{ data.answer }</span>
              }
              { step.type === 'sms' && step.action === 'message' && data.sms &&
                <span>{ data.sms.body }</span>
              }
              { step.type === 'voice' && data.text &&
                <span>Said <Button { ...this._getSayButton(data) } /></span>
              }
              { step.type === 'voice' && data.asset &&
                <span>Played <Button { ...this._getPlayButton(data.asset, 'recording') } /></span>
              }
              { step.type === 'voice' && data.action === 'answer' && data.digits &&
                <span>Dialed { data.digits } - { data.answer }</span>
              }
              { step.type === 'voice' && data.voicemail &&
                <span>Recorded <Button { ...this._getPlayButton(data.voicemail, 'voicemail') } /></span>
              }
              { step.type === 'voice' && step.action === 'question' && data.question &&
                <span>
                  Q: { data.question }<br />
                  A: { data.answer }
                </span>
              }
              { step.type === 'voice' && data.action === 'forward' &&
                <CallActivities call={ call } />
              }
              { step.type === 'administrative' && step.action === 'email' && data.emails &&
                <>
                  { data.emails.map((email, index) => (
                    <div key={`email_${index}`}>
                      { email.email ? 
                        <>Sent internal email { email.email.subject } to { email.email.to[0].rfc822 }</> : 
                        <>Sent internal email { email.subject } to { email.to }</>
                      }                      
                    </div>
                  )) }
                </>
              }
              { step.type === 'administrative' && step.action === 'sms' && data.smses &&
                <>
                  { data.smses.map((sms, index) => (
                    <div key={`sms_${index}`}>
                      Sent internal sms to <PhoneNumber number={ sms.to } /><br />
                      <span>{ sms.body }</span>
                    </div>
                  )) }
                </>
              }
              { step.type === 'administrative' && step.action === 'notification' && data.notifications &&
                <>
                  { data.notifications.map(({ message, to }, index) => (
                    <div key={`sms_${index}`}>
                      Sent notification to { to }<br />
                      { message }
                    </div>
                  )) }
                </>
              }
              { step.type === 'communication' && step.action === 'email' && data.email &&
                <span>Sent <Button { ...this._getEmailButton(data.email) } /> to { data.email.to[0].rfc822 }</span>
              }
              { step.type === 'communication' && step.action === 'sms' && data.sms &&
                <span>{ data.sms.body } to <PhoneNumber number={ data.sms.to } /></span>
              }
            </div>
          }
        </div>
      </div>
    )
  }

  _getAction(action) {
    const { sms, step } = action
    if(step.type === 'voice' && step.action === 'dial') return 'Forward Call'
    if(step.type === 'sms' && step.action === 'question' && !sms) return 'Answer'
    return step.action
  }

  _getDeal(deal) {
    return {
      label: deal.title,
      className: 'link',
      route: `/admin/sales/deals/${deal.id}`
    }
  }

  _getTask(task) {
    return {
      label: task.title,
      className: 'link',
      route: `/admin/tasks/tasks/${task.id}`
    }
  }

  _getIcon() {
    const { expanded } = this.state
    return expanded ? 'caret-down' : 'caret-right'
  }

  _getEmailButton(email) {
    return {
      label: email.subject,
      className: 'link',
      route: `/admin/emails/${email.code}`
    }
  }

  _getPlayButton(asset, label) {
    return {
      label,
      className: 'link',
      handler: this._handlePlay.bind(this, asset)
    }
  }

  _handlePlay(asset) {
    const { admin } = this.context
    const url = admin.signUrl(`/api/admin/${asset.path}/view`)
    const audio = new Audio(url)
    audio.play()
  }

  _getSayButton(data) {
    return {
      label: data.text,
      className: 'link',
      handler: this._handleSay.bind(this, data)
    }
  }

  _handleSay(data) {
    const { admin } = this.context
    const text = `<speak>${data.text.replace('\n', '<break time="1s" />')}</speak>`
    const url = admin.signUrl(`/api/admin/speak?text=${encodeURIComponent(text)}&voice=${data.voice}`)
    const audio = new Audio(url)
    audio.play()
  }

  _handleToggle() {
    const { expanded } = this.state
    const { action } = this.props
    if(action.error || action.failure_code ) return
    this.setState({
      expanded: !expanded
    })
  }

}

export default Action
