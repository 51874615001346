import PaymentField from '@apps/finance/admin/components/paymentfield'
import AmountField from '@apps/finance/admin/components/amountfield'
import Container from '@admin/components/container'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Payment extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    modal: PropTypes.object
  }

  static propTypes = {
    cards: PropTypes.array,
    invoice: PropTypes.object
  }

  state = {
    config: {}
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form {...this._getForm()} />
  }

  _getForm() {
    const { config } = this.state
    const { cards, invoice } = this.props
    return {
      title: 't(Receive Payment)',
      action: `/api/admin/team/invoices/${invoice.id}/payments`,
      method: 'POST',
      onCancel: this._handleCancel,
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { name: 'payment', type: PaymentField, allowedMethods: ['card'], cards, contact: invoice.customer, program: invoice.program, required: true, amount: config.amount },
            { label: 't(Amount)', name: 'amount', type: AmountField, required: true, balance: invoice.balance }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSuccess() {
    this.context.modal.close()
  }

}

const mapResources = (props, context) => ({
  cards: '/api/admin/team/payment_methods',
  invoice: `/api/admin/team/invoices/${props.invoice_id}`
})

export default Container(mapResources)(Payment)
