import CriteriaDesigner from '@admin/components/criteria_designer'
import ModalPanel from '@admin/components/modal_panel'
import RecipientToken from '../../tokens/recipient'
import PropTypes from 'prop-types'
import React from 'react'

class Participants extends React.PureComponent {

  static contextTypes = {
    form: PropTypes.object
  }

  static propTypes = {
    channel: PropTypes.string,
    defaultValue: PropTypes.object,
    endpoint: PropTypes.string,
    fields: PropTypes.array,
    instructions: PropTypes.any,
    program: PropTypes.object,
    program_id: PropTypes.number,
    purpose: PropTypes.string,
    webinar: PropTypes.object,
    onDone: PropTypes.func
  }

  state = {
    criteria: []
  }

  _handleBack = this._handleBack.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleDone = this._handleDone.bind(this)

  render() {
    return (
      <ModalPanel { ...this._getPanel() }>
        <CriteriaDesigner { ...this._getCriteriaDesigner() } />
      </ModalPanel>
    )
  }

  _getCriteriaDesigner() {
    const { channel, defaultValue, endpoint, program_id, purpose, webinar } = this.props
    return {
      defaultValue: defaultValue ? defaultValue.criteria : null,
      defaultQuery: {
        channel,
        program_id,
        purpose,
        webinar_id: webinar.id,
        strategy: 'participants'
      },
      endpoint,
      entity: 'contact',
      fields: [
        {
          label: 'Particpant',
          fields: [
            { name: 'Status', key: 'participant_status', type: 'select', multiple: true, options: ['registered','canceled','joined','noshow','attended'] }
          ]
        }
      ],
      format: (recipient) => <RecipientToken recipient={ recipient } />,
      onChange: this._handleChange
    }
  }

  _getPanel() {
    const { instructions } = this.props
    return {
      title: 't(Select Criteria)',
      instructions,
      leftItems: [
        { icon: 'chevron-left', handler: this._handleBack }
      ],
      rightItems: [
        { label: 't(Done)', handler: this._handleDone }
      ]
    }
  }

  _handleBack() {
    this.context.form.pop()
  }

  _handleChange(criteria) {
    this.setState({ criteria })
  }

  _handleDone() {
    const { criteria } = this.state
    this.props.onDone({ criteria })
    this.context.form.pop()
  }

}

export default Participants
