import AgencyAdvisorSubscription from './agency/advisors/show/subscriptions/show'
import AdminAdvisorSubscription from './admin/advisors/show/subscriptions/show'
import AdminAgencySubscription from './admin/agencies/show/subscriptions/show'
import AgencySocialBatchPublishing from './agency/social_batches/publishing'
import AdminLandingPageShow from './admin/forms/show/landing_pages/show'
import AdminAdvisorBranding from './admin/advisors/show/branding/show'
import AgencySocialPerformance from './agency/social_performance/show'
import AdminParticipant from './admin/webinars/show/participants/show'
import AgencyEmailPerformance from './agency/email_performance/show'
import AdminSocialPublishing from './admin/social_publishings/show'
import AdvisorSubscription from './advisor/show/subscriptions/show'
import AdminAestheticProfile from './admin/aesthetic_profiles/show'
import AgencySocialCampaign from './agency/social_campaigns/show'
import AgencyEmailCampaign from './agency/email_campaigns/show'
import AdminEmailCampaign from './admin/email_campaigns/show'
import AdminDomainSegment from './admin/domain_segments/show'
import AgencySocialBatch from './agency/social_batches/show'
import AdminSenderDomain from './admin/sender_domains/show'
import AdminEmbedShow from './admin/forms/show/embeds/show'
import AdminPricingModel from './admin/pricing_models/show'
import AdminSocialBatch from './admin/social_batches/show'
import AdminVerification from './admin/verifications/show'
import AdminDmarcReport from './admin/dmarc_reports/show'
import AdminSocial from './admin/social_campaigns/show'
import AdminSignupPage from './admin/signup_pages/show'
import AgencyTemplate from './agency/templates/show'
import AgencyAdvisor from './agency/advisors/show'
import AdminTemplate from './admin/templates/show'
import AdminWorkflow from './admin/workflows/show'
import AdminWebinar from './admin/webinars/show'
import AdminAdvisor from './admin/advisors/show'
import AdminAgency from './admin/agencies/show'
import AdminNotice from './admin/notices/show'
import AdminDomain from './admin/domains/show'
import AdminSentShow from './admin/sent/show'
import AdminEmail from './admin/emails/show'
import AdminForm from './admin/forms/show'
import AdminDemo from './admin/demos/show'
import Advisor from './advisor/show'
import Agency from './agency'
import Admin from './admin'

const routes = [
  { path: '/admin', component: Admin },
  { path: '/admin/:view', component: Admin },
  { path: '/admin/agencies/:id/:view', component: AdminAgency },
  { path: '/admin/agencies/:agency_id/subscriptions/:id/:view', component: AdminAgencySubscription },
  { path: '/admin/advisors/:advisor_id/branding/:view', component: AdminAdvisorBranding },
  { path: '/admin/advisors/:advisor_id/subscriptions/:id/:view', component: AdminAdvisorSubscription },
  { path: '/admin/advisors/:id/:view', component: AdminAdvisor },
  { path: '/admin/aesthetic_profiles/:id/:view', component: AdminAestheticProfile },
  { path: '/admin/dmarc_reports/:id/:view', component: AdminDmarcReport },
  { path: '/admin/demos/:id/:view', component: AdminDemo },
  { path: '/admin/domains/:id/:view', component: AdminDomain },
  { path: '/admin/domain_segments/:id/:view', component: AdminDomainSegment },
  { path: '/admin/email_campaigns/:id/:view', component: AdminEmailCampaign },
  { path: '/admin/emails/:id/:view', component: AdminEmail },
  { path: '/admin/forms/:id/:view', component: AdminForm },
  { path: '/admin/forms/:form_id/embeds/:id/:view', component: AdminEmbedShow },
  { path: '/admin/forms/:form_id/landing_pages/:id/:view', component: AdminLandingPageShow },
  { path: '/admin/notices/:id/:view', component: AdminNotice },
  { path: '/admin/pricing_models/:id/:view', component: AdminPricingModel },
  { path: '/admin/sender_domains/:id/:view', component: AdminSenderDomain },
  { path: '/admin/sent/domain_segments/:domain_segment_id/:interval/:value/:view', component: AdminSentShow },
  { path: '/admin/sent/domains/:domain_id/:interval/:value/:view', component: AdminSentShow },
  { path: '/admin/sent/segments/:segment_id/:interval/:value/:view', component: AdminSentShow },
  { path: '/admin/sent/:interval/:value/:view', component: AdminSentShow },
  { path: '/admin/signup_pages/:id/:view', component: AdminSignupPage },
  { path: '/admin/social_batches/:id/:view', component: AdminSocialBatch },
  { path: '/admin/social_campaigns/:id/:view', component: AdminSocial },
  { path: '/admin/social_publishings/:id/:view', component: AdminSocialPublishing },
  { path: '/admin/templates/:id/:view', component: AdminTemplate },
  { path: '/admin/verifications/:id', component: AdminVerification },
  { path: '/admin/webinars/:webinar_id/participants/:id/:view', component: AdminParticipant },
  { path: '/admin/webinars/:id/:view', component: AdminWebinar },
  { path: '/admin/workflows/:id/:view', component: AdminWorkflow },
  { path: '/advisor', component: Advisor },
  { path: '/advisor/subscriptions/:id/:view', component: AdvisorSubscription },
  { path: '/advisor/:view', component: Advisor },
  { path: '/agency', component: Agency },
  { path: '/agency/:view', component: Agency },
  { path: '/agency/advisors/:advisor_id/subscriptions/:id/:view', component: AgencyAdvisorSubscription },
  { path: '/agency/advisors/:id/:view', component: AgencyAdvisor },
  { path: '/agency/email_campaigns/:id/:view', component: AgencyEmailCampaign },
  { path: '/agency/email_performance/:id/:view', component: AgencyEmailPerformance },
  { path: '/agency/social_batches/:social_batch_id/publishings/:id/:view', component: AgencySocialBatchPublishing },
  { path: '/agency/social_batches/:id/:view', component: AgencySocialBatch },
  { path: '/agency/social_campaigns/:id/:view', component: AgencySocialCampaign },
  { path: '/agency/social_performance/:id/:view', component: AgencySocialPerformance },
  { path: '/agency/templates/:id/:view', component: AgencyTemplate }
]

export default routes
