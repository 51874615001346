import Details from '@admin/components/details'
import Panel from '@admin/components/panel'
import PropTypes from 'prop-types'
import React from 'react'

const Overview = ({ webinar }) => {

  const details = {
    audits: `truevail_webinars/${webinar.id}`,
    comments: `truevail_webinars/${webinar.id}`,
    sections: [
      {
        title: 't(Webinar)',
        items: [
          { label: 't(Title)', content: webinar.title },
          { label: 't(Start Time)', content: webinar.started_at, format: 'datetime' },
          { label: 't(End Time)', content: webinar.ended_at, format: 'datetime' }
        ]
      }
    ]
  }
  
  return <Details { ...details } />

}

Overview.propTypes = {
  webinar: PropTypes.object
}

const mapPropsToPanel = (props, context) => ({
  title: 't(Overview)',
  panel: <Overview webinar={ props.webinar } />,
  tasks: {
    items: [
    ]
  }
})

export default Panel(null, mapPropsToPanel)
